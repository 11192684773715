import { state } from '@angular/animations';
import { createReducer, on } from '@ngrx/store';
import { History } from '.';
import * as HistoryData from '../actions/history.actions';

export const initialState: History[] = [];

export const HistoryReducer = createReducer(
  initialState,
  on(HistoryData.setCardDesc, (state, { history }) => [...state, history]),
  on(HistoryData.setCard, (state, { history }) => initialState),
  on(HistoryData.popHistory, (state) => {
    let copy = Array.from(state);
    copy.pop();
    return copy;
  })
);
