import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TextsService {
  LANDING_TITLE: string = 'Living in My Shoes';
  LANDING_SUB_TITLE: string = 'AN ANTI-POVERTY SIMULATION';
  LANDING_DESCRIPTION: string[] = [
    `We love where we live. But local issues like childhood poverty are hurting our community. In our busy lives, these local issues can be easy to ignore or go unnoticed. And you can't solve a problem if you don't know it exists.`,

    `We're working to make this local issue #UNIGNORABLE.`,

    `Come walk a day in the shoes of Jane and Joe. Learn more about the experiences of real families living in low-income in Windsor-Essex County.`,
  ];
  PERSONA_SELECTOR: string = 'Choose a Profile';
  ERROR_MESSAGE_DEFAULT: string = 'Please Select';
  ERROR_MESSAGE_DUPLICATE: string = 'Values must be Unique';
  PERSONA_INFORMATION: string = 'Information';
  PERSONA_BACKGROUND: string = 'Background';
  PERSONA_FUTURE: string = 'Future';
  FOOTER_RIS_TEXT: string = 'MADE BY';
  OFF_BOARDING_TITLE: string = 'Show your local love!';
  OFF_BOARDING_CONTENT: object = {
    cards: [
      {
        title: 'Donate Today',
        tabTitle: 'Donate',
        description:
          'Invest in your community and give children, friends and neighbours help when they need it most. Every dollar of your donation is invested right here in Windsor-Essex County.',
        link: 'https://www.weareunited.com/donate/',
        image: '../../../assets/images/card-image/donate-today.jpg',
      },
      {
        title: 'Get  Involved',
        tabTitle: 'Involve',
        description:
          'Contact us to learn about how you can get involved with United Way Volunteer Opportunities. ',
        link: 'mailto:info@weareunited.com',
        image: '../../../assets/images/card-image/get-involved.jpg',
      },
      {
        title: 'Read about our Impact',
        tabTitle: 'Impact',
        description:
          'Learn about how donors like you are creating prosperous futures for kids and families right here in Windsor-Essex.',
        link: 'https://www.weareunited.com/impact/',
        image: '../../../assets/images/card-image/read-our-impact.jpg',
      },
    ],
  };

  constructor() {}
}
