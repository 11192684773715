<div id="page">
  <div id="content" [@primerCard]="isDesktop ? animationState : ''">
    <h1 id="header">Before You Get Started</h1>
    <div id="copy-1">
      As a part of this simulation, you will choose the profile of either Jane
      or Joe. These profiles were developed after speaking with and observing
      more than 300 youth and families in Windsor-Essex.
      <b>You are about to live a day in someone else’s shoes, and experience
      some of the challenges that they might face.</b>
    </div>
    <app-button
      id="next-button"
      (click)="handleClick()"
      [buttonText]="'Next'"
      [buttonType]="'button-primary'"
    >
    </app-button>
  </div>
</div>
