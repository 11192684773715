import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-carousel-buttons',
  templateUrl: './carousel-buttons.component.html',
  styleUrls: ['./carousel-buttons.component.scss'],
})
export class CarouselButtonsComponent implements OnInit {
  @Output() handleAction: EventEmitter<number> = new EventEmitter();

  @Input() items: any[];
  @Input() activeIndex: number = 0;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.activeIndex.currentValue >= 0) {
      this.activeIndex = changes.activeIndex.currentValue;
    }
  }

  onCarouselClick(itemIndex: number): void {
    this.activeIndex = itemIndex;
    this.handleAction.emit(this.activeIndex);
  }
}
