import { Component, Input, OnInit } from '@angular/core';
import { Table } from 'src/app/model/experiences/table.model';

@Component({
  selector: 'app-card-table',
  templateUrl: './card-table.component.html',
  styleUrls: ['./card-table.component.scss'],
})
export class CardTableComponent {
  @Input() tableData: Table;
}
