import { createReducer, on } from '@ngrx/store';
import { EventState } from '.';
import * as EventActions from '../actions/event.actions';

export const initialState: EventState = {
  eventId: null,
  cardType: 'DECISION',
  geoEvent: null,
};

export const EventReducer = createReducer(
  initialState,
  on(EventActions.setEventId, (state: EventState, { eventId }) =>
    Object.assign({}, state, { eventId: eventId })
  ),
  on(EventActions.setEventCardType, (state: EventState, { cardType }) =>
    Object.assign({}, state, { cardType: cardType })
  ),
  on(EventActions.setEventGeoData, (state: EventState, { geoEvent }) =>
    Object.assign({}, state, { geoEvent: geoEvent })
  )
);
