<div class="landing-container">
  <img
    src="../../../assets/images/background/shoes-on-yellow-line.jpg"
    alt=""
    class="landing-mobile-image"
  />
  <sprk-modal
    [(isVisible)]="showBrowserNotification"
    title="Heads up!"
    modalType="info"
    idString="modal-info-1"
    (hide)="closeBrowserNotification()"
  >
    For the best in-person experience we recommend that you use Chrome.
  </sprk-modal>
  <div
    [@landingCard]="isDesktop ? animationState : ''"
    class="landing-information"
  >
    <h1 class="landing-header">{{ TEXT.LANDING_TITLE }}</h1>
    <p class="landing-subtitle">{{ TEXT.LANDING_SUB_TITLE }}</p>
    <div class="landing-description-container">
      <p
        *ngFor="let description of TEXT.LANDING_DESCRIPTION"
        class="landing-description"
      >
        {{ description }}
      </p>
    </div>

    <div class="landing-button-container">
      <app-button
        [buttonType]="'button-primary'"
        [buttonText]="'Get Started'"
        (click)="handleButtonPress()"
      >
      </app-button>
    </div>
  </div>
  <sprk-modal
    [(isVisible)]="choiceVisible"
    title="Would you like to pick up where you left off?"
    modalType="info"
    idString="new-game-choice"
    id="new-game-choice"
    (hide)="hideModal()"
  >
    We noticed you left before finishing the simulation. Would you like to
    continue, or start over?
    <div id="modal-buttons">
      <div id="confirm-button">
        <app-button
          [buttonText]="'Continue'"
          [buttonType]="'button-primary'"
          class="card-button"
          (click)="continueClick()"
        >
        </app-button>
      </div>
      <div id="cancel-button">
        <app-button
          [buttonText]="'Start Over'"
          [buttonType]="'button-transparent'"
          class="card-button"
          (click)="newGameClick()"
        >
        </app-button>
      </div>
    </div>
  </sprk-modal>
</div>
