import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeUnderScore' })
export class RemoveUnderScorePipe implements PipeTransform {
  re = /_/g;
  constructor() {}

  transform(value: string): string {
    return value.replace(this.re, ' ');
  }
}
