import { Component, OnInit, HostListener } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import * as resourceSelector from '../../../store/selectors/game-resource.selectors';
import { Store, select } from '@ngrx/store';
import { TimerService } from '../../../services/timer/timer.service';
import { ConstantsService } from '../../../statics/constants/constants.service';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
})
export class MobileHeaderComponent implements OnInit {
  showModal: boolean = false;
  hours: number = 0;
  minutes: number = 0;
  seconds: number = 0;
  listenerSubscription: Subscription[] = [];

  totalSeconds: number = null;
  totalSeconds$: Observable<number> = this._store.pipe(
    select(resourceSelector.selectTime)
  );
  money: number = null;
  money$: Observable<number> = this._store.pipe(
    select(resourceSelector.selectMoney)
  );

  constructor(
    readonly CONSTANT: ConstantsService,
    private readonly _store: Store,
    private readonly _timer: TimerService
  ) {}

  ngOnInit(): void {
    this._timer.startTimer();
    this.listenerSubscription.push(
      this.money$.subscribe((data) => (this.money = data))
    );
    this.listenerSubscription.push(
      this.totalSeconds$.subscribe((data) => {
        this.totalSeconds = data;
        this.hours = Math.floor(this.totalSeconds / 3600);
        this.totalSeconds %= 3600;
        this.minutes = Math.floor(this.totalSeconds / 60);
        this.seconds = this.totalSeconds % 60;
      })
    );
  }

  closeModal(): void {
    this.showModal = false;
  }

  toggleModal(): void {
    this.showModal = !this.showModal;
  }

  // Unsubscribing from subscriptions at the end
  ngOnDestroy(): void {
    this.listenerSubscription.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
