<div id="sidebar-content">
  <div>
    <div id="clock" class="sidebar-image">
      <img
        src="../assets/images/icons/watch-transparent-face.png"
        alt="clock"
        id="clock"
      />
      <div
        class="clock-hand-min"
        [ngStyle]="{ transform: 'rotate(' + getMinuteRotateDegree() + ')' }"
      ></div>
      <div
        class="clock-hand-hour"
        [ngStyle]="{ transform: 'rotate(' + getHourRotateDegree() + ')' }"
      ></div>
    </div>
  </div>
  <div id="goals-container">
    <div id="notepad">
      <img
        src="../../../../assets/images/icons/notepad.png"
        id="notepadImage"
      />
    </div>
    <div id="goalsList">
      <div class="goalWrapper" *ngFor="let goal of goals">
        <app-goal-item
          [ngClass]="goal.startingGoal ? 'starting-goal' : 'new-goal'"
          [goal]="goal"
        ></app-goal-item>
      </div>
    </div>
  </div>
</div>
