<div id="footer" splitAt="tiny">
  <div>
    <p class="rocket-text">{{ TEXT.FOOTER_RIS_TEXT }}</p>
    <a [attr.href]="RIS_HOME" target="_blank">
      <img
        src="../assets/images/icons/RIS_Icon.png"
        alt="RIS-logo"
        id="header-logo"
        class="logo-RIS"
      />
    </a>
  </div>
  <span *ngIf="this.router.url !== '/gamescreen'" class="footer-text">
    Contact us at
    <a [attr.href]="'mailto:' + UW_EMAIL_CONTACT" target="_blank">{{
      UW_EMAIL_CONTACT
    }}</a>
  </span>
  <app-progress-bar
    *ngIf="this.router.url === '/gamescreen'"
    class="progress-bar"
  ></app-progress-bar>
  <div>
    <a [attr.href]="UW_ABOUT_US" target="_blank">
      <img
        src="../assets/images/icons/united-way.png"
        alt="UnitedWay-logo"
        id="header-logo"
        class="logo-united-way"
      />
    </a>
  </div>
</div>
