import { createAction, props } from '@ngrx/store';
import { History } from '../reducers';

export const setCardDesc = createAction(
  '[Game Screen] Set Card Description',
  props<{ history: History }>()
);

export const setCard = createAction(
  '[Game Screen] Set Card',
  props<{ history: History }>()
);

export const popHistory = createAction('[History] Popping history');
