import { createReducer, on } from '@ngrx/store';
import * as FormActions from '../actions/form.actions';

export const initialState = {};

export const FormReducer = createReducer(
  initialState,
  on(FormActions.setFormInput, (state, { form_input }) => {
    return { ...state, ...form_input };
  }),
  on(FormActions.setIncorrectInput, (state) => {
    const formState = { ...state };
    const correctInput = {};
    for (let key in formState) {
      if (formState[key]['isCorrect']) {
        correctInput[key] = { ...formState[key] };
        correctInput[key]['isCorrect'] = false;
        delete formState[key];
      }
    }
    return { ...formState, ...correctInput };
  }),
  on(FormActions.clearCorrectInput, (state) => {
    const formState = { ...state };
    for (let key in formState) {
      if (formState[key]['isCorrect']) {
        delete formState[key];
      }
    }
    return { ...formState };
  }),
  on(FormActions.clearFormInput, (state) => {
    return {};
  })
);
