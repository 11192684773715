import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import * as resourceSelector from '../../../store/selectors/game-resource.selectors';
import { Store, select } from '@ngrx/store';
import { PushNotification } from 'src/app/model/experiences/push-notification.model';
import { Transaction } from 'src/app/model/experiences/transaction.model';
import * as personaSelectors from '../../../store/selectors/persona.selectors';
import { Persona } from 'src/app/model/personas/persona.model';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
})
export class PhoneComponent implements OnInit {
  hours: number = 0;
  minutes: number = 0;
  seconds: number = 0;
  listenerSubscription: Subscription[] = [];

  totalSeconds: number = null;
  totalSeconds$: Observable<number> = this._store.pipe(
    select(resourceSelector.selectTime)
  );
  pushNotifications$: Observable<PushNotification[]> = this._store.pipe(
    select(resourceSelector.selectPushNotifications)
  );
  transactions$: Observable<Transaction[]> = this._store.pipe(
    select(resourceSelector.selectTransactions)
  );
  transactions: Transaction[] = [];
  money$: Observable<number> = this._store.pipe(
    select(resourceSelector.selectMoney)
  );
  money: number = null;
  tabs: Record<string, boolean> = {
    balance: true,
    profile: false,
  };
  notifications: PushNotification[] = [];
  personas: Persona[] = null;
  persona$: Observable<Persona[]> = this._store.pipe(
    select(personaSelectors.selectPersonas)
  );
  selectedPersona: number = null;
  selectedPersona$: Observable<number> = this._store.pipe(
    select(personaSelectors.selectSelectedId)
  );

  persona: Persona = null;
  currentDate: string;

  constructor(private readonly _store: Store) {}

  ngOnInit(): void {
    this.listenerSubscription.push(this.totalSeconds$.subscribe((data) => {
      this.totalSeconds = data;
      this.hours = Math.floor(this.totalSeconds / 3600) % 12;
      this.totalSeconds %= 3600;
      this.minutes = Math.floor(this.totalSeconds / 60);
      this.seconds = this.totalSeconds % 60;
    }));
    this.listenerSubscription.push(this.pushNotifications$.subscribe((data) => (this.notifications = data)));
    this.listenerSubscription.push(this.transactions$.subscribe((data) => (this.transactions = data)));
    this.listenerSubscription.push(this.money$.subscribe((data) => (this.money = data)));
    this.listenerSubscription.push(this.persona$.subscribe((data) => (this.personas = data)));
    this.listenerSubscription.push(this.selectedPersona$.subscribe((data) => (this.selectedPersona = data)));
    this.persona = this.personas?.find((x) => x.id === this.selectedPersona);
    this.currentDate = new Date(Date.now()).toLocaleString(undefined, {
      month: 'short',
      day: 'numeric',
      weekday: 'short',
    });
  }

  /**
   * @returns The current amount of money the user has.
   */
  getRemainingBalance(): number {
    return this.money;
  }

  handleWidgetClick(selection: string): void {
    switch (selection) {
      case 'balance':
        this.tabs.balance = true;
        this.tabs.profile = false;
        break;
      case 'profile':
        this.tabs.balance = false;
        this.tabs.profile = true;
        break;
      default:
        break;
    }
  }

  /**
   * Assigns specific colors to tabs on phone component.
   * @param tab The tab that will be styled.
   * @returns Appropriate styling for the tab.
   */
  styleTabs(tab: string): Record<string, string> {
    if (tab === Object.keys(this.tabs).find((t) => this.tabs[t] === true)) {
      return { color: '#eab432' };
    } else {
      return { color: 'white' };
    }
  }

  // Unsubscribing from subscriptions at the end
  ngOnDestroy(): void {
    this.listenerSubscription.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
