import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsService } from 'src/app/statics/constants/constants.service';
import { DOCUMENT } from '@angular/common';
import { TextsService } from 'src/app/statics/texts/texts.service';

enum AnimationStates {
  None = 'none',
  SwipeLeft = 'swipeLeft',
  SwipeRight = 'swipeRight',
}

const transitionAnimationLength = 0.3;

@Component({
  selector: 'app-landing',
  templateUrl: './offboarding.component.html',
  styleUrls: ['./offboarding.component.scss'],
  animations: [
    trigger('cardSwipe', [
      transition(
        'none=>swipeLeft',
        animate(`${transitionAnimationLength}s ease-in-out`)
      ),
      transition(
        'none=>swipeRight',
        animate(`${transitionAnimationLength}s  ease-in-out`)
      ),
      state('none', style({ transform: 'translateX(0)', opacity: 1 })),
      state('swipeLeft', style({ transform: 'translateX(-100%)', opacity: 0 })),
      state('swipeRight', style({ transform: 'translateX(100%)', opacity: 0 })),
    ]),
  ],
})
export class OffboardingComponent implements OnInit, AfterViewInit {
  currentCard: number = 0;
  offboardingContents: object[] = null;
  screenWidth: number = null;
  isMobile: boolean = false;
  @ViewChild('offboardingCard', { static: false }) offboardingCard: ElementRef;

  animationState: AnimationStates = AnimationStates.None;

  constructor(
    readonly TEXT: TextsService,
    readonly CONSTANT: ConstantsService,
    readonly router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.offboardingContents = TEXT.OFF_BOARDING_CONTENT['cards'];
  }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
  }

  ngAfterViewInit() {
    if (this.screenWidth < this.CONSTANT.TABLET_PORTRAIT_THRESHOLD) {
      this.isMobile = true;
      this.showHideCards();
    }
  }

  /**
   * Handles triggering the animation when the user swipes right.
   */
  onRightSwipe() {
    this.animationState = AnimationStates.SwipeRight;
    setTimeout(() => {
      this.animationState = AnimationStates.None;
      const previousIndex = this.currentCard - 1;
      const totalPersonas = this.offboardingContents.length;
      this.currentCard =
        ((previousIndex % totalPersonas) + totalPersonas) % totalPersonas;
      this.showHideCards();
    }, transitionAnimationLength * 1000);
  }

  /**
   * Handles triggering the animation when the user swipes left.
   */
  onLeftSwipe() {
    this.animationState = AnimationStates.SwipeLeft;
    setTimeout(() => {
      this.animationState = AnimationStates.None;
      this.currentCard + 1 < this.offboardingContents.length
        ? (this.currentCard += 1)
        : (this.currentCard = 0);
      this.showHideCards();
    }, transitionAnimationLength * 1000);
  }

  /**
   * Handles nagivation when a card is clicked.
   */
  handleCardClick(cardNumber: number) {
    window.open(this.offboardingContents[cardNumber]['link'], '_blank');
  }

  /**
   * Changes cards when a new tab is selected.
   */
  onTabChange(cardId: number) {
    this.currentCard = cardId;
    this.showHideCards();
  }

  /**
   * Toggles displaying the cards for mobile and desktop.
   */
  showHideCards() {
    this.offboardingCard.nativeElement
      .querySelectorAll('.offboarding-card')
      .forEach((element: HTMLElement) => {
        if (+element.id.split('-')[1] !== this.currentCard && this.isMobile) {
          element.style.display = 'none';
        } else {
          element.style.display = 'unset';
        }
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < this.CONSTANT.TABLET_PORTRAIT_THRESHOLD) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.showHideCards();
  }
}
