<link rel="preconnect" href="https://fonts.gstatic.com" />
<link
  href="https://fonts.googleapis.com/css2?family=Liu+Jian+Mao+Cao&display=swap"
  rel="stylesheet"
/>
<div [@goalItem]="goalItemState">
  <span
    [@strikeThrough]="strikeThroughState"
    style="font: 600 2.5vh 'Liu Jian Mao Cao', cursive;"
  >
    {{ goal.text }}
  </span>
</div>
