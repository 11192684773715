import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as resourceActions from '../../../store/actions/game-resource.actions';

@Injectable({
  providedIn: 'root',
})
export class ResourceHandlerService {
  constructor(private readonly _store: Store) {}

  addMoney(incr: number): void {
    this._store.dispatch(resourceActions.setaddMoney({ money: incr }));
  }

  removeMoney(decr: number): void {
    this._store.dispatch(resourceActions.setremoveMoney({ money: decr }));
  }

  addTime(incr: number): void {
    this._store.dispatch(resourceActions.setaddTime({ time: incr }));
  }

  removeTime(decr: number): void {
    this._store.dispatch(resourceActions.setremoveTime({ time: decr }));
  }
}
