import {
  trigger,
  transition,
  style,
  animate,
  state,
} from '@angular/animations';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { PushNotification } from 'src/app/model/experiences/push-notification.model';
export enum AnimationStates {
  None = 'none',
  Leaving = 'leaving',
  Entering = 'entering',
}

@Component({
  selector: 'app-phone-notification',
  templateUrl: './phone-notification.component.html',
  styleUrls: ['./phone-notification.component.scss'],
  animations: [
    trigger('loadCard', [
      transition(
        'none => leaving',
        animate('0.5s cubic-bezier(.8, -0.6, 0.2, 1.5)')
      ),
      transition(
        'entering => none',
        animate('1.5s cubic-bezier(.8, -0.6, 0.2, 1.5)')
      ),
      state('none', style({ opacity: 1, transform: 'scale(1)' })),
      state('entering', style({ opacity: 0, transform: 'scale(0.5)' })),
      state('leaving', style({ opacity: 0, transform: 'scale(0.5)' })),
    ]),
  ],
})
export class PhoneNotificationComponent implements OnInit, AfterViewInit {
  @Input() notification: PushNotification;

  animationState = AnimationStates.Entering;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => (this.animationState = AnimationStates.None));
  }
}
