import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LandingComponent } from './pages/landing/landing.component';
import { PersonaSelectComponent } from './pages/persona-select/persona-select.component';
import { GameScreenComponent } from './pages/game-screen/game-screen.component';
import { DirectAccessGuardService } from './services/route-guards/direct-access-guard.service';
import { PersonaPrimerComponent } from './pages/persona-primer/persona-primer.component';
import { OffboardingComponent } from './pages/offboarding/offboarding.component';

const routes: Routes = [
  { path: 'home', component: LandingComponent },
  {
    path: 'personas',
    component: PersonaSelectComponent,
    canActivate: [DirectAccessGuardService],
  },
  {
    path: 'gamescreen',
    component: GameScreenComponent,
    canActivate: [DirectAccessGuardService],
  },
  {
    path: 'offboarding',
    component: OffboardingComponent,
    canActivate: [DirectAccessGuardService],
  },
  {
    path: 'primer',
    component: PersonaPrimerComponent,
    canActivate: [DirectAccessGuardService],
  },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
export const RoutingComponent = [
  LandingComponent,
  PersonaSelectComponent,
  GameScreenComponent,
  PersonaPrimerComponent,
  OffboardingComponent,
];
