import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { Persona } from '../../..//model/personas/persona.model';
import { ConstantsService } from '../../../statics/constants/constants.service';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as personaSelectors from '../../../store/selectors/persona.selectors';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() displayModal: boolean = null;
  @Input() modalType: string = null;
  @Output() closeModalEvent = new EventEmitter();
  @Input() title: string = null;
  @Input() text: string = null;
  @Input() ButtonText: string = null;
  showCancelIcon: boolean = false;
  showButton: boolean = false;
  modalContainer: string = null;
  modalDesc: string = null;
  modalHeader: string = null;
  persona: Persona = null;
  headerImage: string = null;
  modalButton: string = null;
  listenerSubscription: Subscription[] = [];

  personas: Persona[] = null;
  persona$: Observable<Persona[]> = this._store.pipe(
    select(personaSelectors.selectPersonas)
  );
  selectedPersona: number = null;
  selectedPersona$: Observable<number> = this._store.pipe(
    select(personaSelectors.selectSelectedId)
  );

  constructor(
    readonly CONSTANT: ConstantsService,
    private readonly _store: Store
  ) {}

  ngOnInit(): void {
    this.listenerSubscription.push(
      this.persona$.subscribe((data) => (this.personas = data))
    );
    this.listenerSubscription.push(
      this.selectedPersona$.subscribe((data) => (this.selectedPersona = data))
    );
    this.persona = this.personas?.find((x) => x.id === this.selectedPersona);

    switch (this.modalType) {
      case this.CONSTANT.MODAL_GAMESCREEN_MENU_CHARACTER_INFO:
        this.modalContainer = 'menu-modal-container';
        this.modalHeader = 'menu-modal-header';
        this.headerImage = '../../../../assets/images/icons/info.svg';
        this.modalDesc = 'menu-modal-description';
        this.text = this.persona?.summary;
        this.showButton = true;
        this.modalButton = 'menu-modal-button';
        break;
      case this.CONSTANT.MODAL_PENALTY_TYPE:
        this.modalContainer = 'hint-modal-container';
        this.modalHeader = 'hint-modal-header';
        this.headerImage = '../../../../assets/images/icons/hint.svg';
        this.modalDesc = 'hint-modal-description';
        this.showButton = true;
        this.modalButton = 'hint-modal-button';
        break;
    }
  }

  hideModal(): void {
    if (this.modalType !== this.CONSTANT.MODAL_PENALTY_TYPE) {
      this.displayModal = !this.displayModal;
      this.closeModalEvent.emit();
    }
  }

  continueModal(): void {
    this.displayModal = !this.displayModal;
    this.closeModalEvent.emit();
  }

  // Unsubscribing from subscriptions at the end
  ngOnDestroy(): void {
    this.listenerSubscription.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
