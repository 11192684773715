<div class="statusBar">
  <div class="dropdown">
    <img src="/assets/images/icons/info-icon.svg" class="information-icon" (click)="toggleModal()" alt="information">
  </div>
  <div id="clock-container">
    <img
      src="/assets/images/icons/time-clock.svg"
      alt="logo"
      id="header-logo"
    />
    <span id="time-style">
      {{ hours | doubledigit }}:{{ minutes | doubledigit }}
    </span>
  </div>
  <div id="wallet-container">
    <span id="wallet-style"> {{ money | currency }} </span>
  </div>
</div>

<app-modal
  [displayModal]="showModal"
  [modalType]="CONSTANT.MODAL_GAMESCREEN_MENU_CHARACTER_INFO"
  [ButtonText]="'Continue'"
  (closeModalEvent)="closeModal()"
></app-modal>
