import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsService } from '../../statics/constants/constants.service';
import { TextsService } from '../../statics/texts/texts.service';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as personaSelectors from '../../store/selectors/persona.selectors';
import * as personaActions from '../../store/actions/persona.actions';
import * as eventActions from '../../store/actions/event.actions';
import { Persona } from '../../model/personas/persona.model';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import * as experienceSelectors from '../../store/selectors/experience.selectors';
import { Experience } from 'src/app/model';
import { ActionHandlerService } from 'src/app/services/handler/action-handler/action-handler.service';
enum AnimationStates {
  None = 'none',
  Entering = 'entering',
}

@Component({
  selector: 'app-persona-select',
  templateUrl: './persona-select.component.html',
  styleUrls: ['./persona-select.component.scss'],
  animations: [
    trigger('bio', [
      transition('entering => none', animate('700ms 0s ease-in')),
      state('none', style({ transform: 'translateY(0)', opacity: 1 })),
      state('entering', style({ transform: 'translateY(8%)', opacity: 0 })),
    ]),
    trigger('bioMobile', [
      transition('entering => none', animate('700ms 0s ease-in')),
      state('none', style({ opacity: 1 })),
      state('entering', style({ opacity: 0 })),
    ]),
    trigger('buttons', [
      transition('entering => none', animate('700ms 0s ease-in')),
      state('none', style({ transform: 'translateY(0)', opacity: 1 })),
      state('entering', style({ transform: 'translateY(8%)', opacity: 0 })),
    ]),
    trigger('buttonsMobile', [
      transition('entering => none', animate('700ms 0s ease-in')),
      state('none', style({ opacity: 1 })),
      state('entering', style({ opacity: 0 })),
    ]),
  ],
})
export class PersonaSelectComponent implements OnInit {
  personas$: Observable<Persona[]> = this._store.pipe(
    select(personaSelectors.selectPersonas)
  );
  personas: Persona[] = new Array<Persona>();
  personaVisited: boolean[] = [true]; // first persona is visisted by default

  //Grab the current experience. We'll use this to fetch the actions/events that we need to initialize the game.
  experience: Experience = null;
  experience$: Observable<Experience> = this._store.pipe(
    select(experienceSelectors.selectExperience)
  );

  listenerSubscription: Subscription[] = [];
  currentChar: number = 0;
  buttonsAnimationState: AnimationStates = AnimationStates.Entering;
  bioAnimationState: AnimationStates = AnimationStates.Entering;
  isDesktop: boolean;

  constructor(
    private _router: Router,
    private readonly _store: Store,
    readonly CONSTANT: ConstantsService,
    readonly TEXT: TextsService,
    readonly _actionHandler: ActionHandlerService
  ) {}

  ngOnInit(): void {
    this.listenerSubscription.push(
      this.personas$.subscribe((data) => (this.personas = data))
    );
    this.listenerSubscription.push(
      this.experience$.subscribe((data) => (this.experience = data))
    );
    if (window.innerWidth < this.CONSTANT.TABLET_PORTRAIT_THRESHOLD) {
      this.isDesktop = false;
    } else {
      this.isDesktop = true;
    }
    setTimeout(() => {
      this.bioAnimationState = AnimationStates.None;
    }, 1000);
    setTimeout(() => {
      this.buttonsAnimationState = AnimationStates.None;
    }, 2000);
  }

  /**
   * @returns The selected persona, if it exists.
   */
  getSelectedPersona() {
    if (this.personas) return this.personas[this.currentChar];
    return null;
  }

  /**
   * @returns The name of the selected persona, if it exists.
   */
  getPersonaName() {
    return this.personas ? this.personas[this.currentChar].first_name : null;
  }

  handleNavigation = (): void => {
    this.setStore();
    this._router.navigate(['/gamescreen']);
  };

  /**
   * Handles the changing of content, and animation triggers.
   * @param direction Whether the left or right arrow was pressed.
   */
  handlePersonaChange(direction: number): void {
    const totalLength = this.personas.length;
    if (totalLength === 0) return;
    this.currentChar =
      (((this.currentChar + direction) % totalLength) + totalLength) %
      totalLength;

    if (!this.personaVisited[this.currentChar]) {
      this.personaVisited[this.currentChar] = true;
      this.bioAnimationState = AnimationStates.Entering;
      this.buttonsAnimationState = AnimationStates.Entering;
      setTimeout(() => {
        this.bioAnimationState = AnimationStates.None;
      }, 1000);
      setTimeout(() => {
        this.buttonsAnimationState = AnimationStates.None;
      }, 2000);
    }
    this.scrollToTop();
  }

  /**
   * Initializes the game store.
   */
  setStore(): void {
    let selectedPersona: Persona = this.personas[this.currentChar];
    this._store.dispatch(
      personaActions.selectPersona({
        selectedId: selectedPersona.id,
      })
    );

    this._store.dispatch(
      eventActions.setEventId({
        eventId: 0,
      })
    );

    if (this.experience) {
      //lets look at the experience we have selected, and fire off the first action.
      this._actionHandler.actionHandler(0, 0, '3600');
    } else {
      //otherwise just fire these off as they were.
      this._store.dispatch(
        eventActions.setEventCardType({
          cardType: this.CONSTANT.DECISION_CARD_TYPE,
        })
      );
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < this.CONSTANT.TABLET_PORTRAIT_THRESHOLD) {
      this.isDesktop = false;
    } else {
      this.isDesktop = true;
    }
  }

  scrollToTop(): void {
    document.getElementById('scrollable').scrollTop = 0;
  }

  // Unsubscribing from subscriptions at the end
  ngOnDestroy(): void {
    this.listenerSubscription.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
