import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as resourceSelector from '../store/selectors/game-resource.selectors';

@Pipe({ name: 'injectvalues' })
export class InjectValuesPipe implements PipeTransform {
  money: number = null;
  money$: Observable<number> = this._store.pipe(
    select(resourceSelector.selectMoney)
  );

  constructor(private readonly _store: Store) { }

  transform(value): string {
    this.money$.subscribe((data) => (this.money = data));
    value = value.replaceAll('@@TOTALMONEY', '$' + this.money.toFixed(2));
    return value;
  }
}
