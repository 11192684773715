import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { ConstantsService } from '../../statics/constants/constants.service';
import { TextsService } from '../../statics/texts/texts.service';
import { ReadJSONService } from '../../services/read-json/read-json.service';

import * as Actions from '../../store/actions';
import * as historySelectors from '../../store/selectors/history.selectors';
import { Observable, Subscription } from 'rxjs';
import { History } from '../../store/reducers';
import { StateHandlerService } from 'src/app/services/handler/state-handler/state-handler.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  BotInfo,
  BrowserInfo,
  detect,
  NodeInfo,
  ReactNativeInfo,
  SearchBotDeviceInfo,
} from 'detect-browser';

enum AnimationStates {
  None = 'none',
  Entering = 'entering',
}
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  animations: [
    trigger('landingCard', [
      transition('entering => none', animate('700ms 0s ease-in')),
      state('none', style({ transform: 'translateY(0)', opacity: 1 })),
      state('entering', style({ transform: 'translateY(8%)', opacity: 0 })),
    ]),
  ],
})
export class LandingComponent implements OnInit {
  constructor(
    private _router: Router,
    private readonly _store: Store,
    readonly CONSTANT: ConstantsService,
    readonly TEXT: TextsService,
    readonly _json: ReadJSONService,
    readonly _stateService: StateHandlerService,
    private _route: ActivatedRoute
  ) {
    this.listenerSubscription.push(
      this.history$.subscribe((data) => (this.history = data))
    );
  }

  listenerSubscription: Subscription[] = [];
  animationState: AnimationStates = AnimationStates.Entering;
  isDesktop: boolean;
  choiceVisible: boolean = false;
  experienceType: string = '';
  history: History[] = null;
  history$: Observable<History[]> = this._store.pipe(
    select(historySelectors.selectHistoryStack)
  );
  showBrowserNotification: boolean = false;

  ngOnInit() {
    if (window.innerWidth < this.CONSTANT.TABLET_PORTRAIT_THRESHOLD) {
      this.isDesktop = false;
    } else {
      this.isDesktop = true;
    }
    setTimeout(() => {
      this.animationState = AnimationStates.None;
    });
    this.listenerSubscription.push(
      this._route.queryParams.subscribe((params) => {
        if (params.inPerson === 'true') {
          this._store.dispatch(
            Actions.setMode({ mode: this.CONSTANT.IN_PERSON_EXP })
          );
          this.experienceType = this.CONSTANT.IN_PERSON_EXP;
        } else {
          this._store.dispatch(
            Actions.setMode({ mode: this.CONSTANT.VIRTUAL_EXP })
          );
          this.experienceType = this.CONSTANT.VIRTUAL_EXP;
        }
      })
    );

    const browser:
      | BrowserInfo
      | SearchBotDeviceInfo
      | BotInfo
      | NodeInfo
      | ReactNativeInfo = detect();

    this.showBrowserNotification =
      this.experienceType === this.CONSTANT.IN_PERSON_EXP &&
      !this.isDesktop &&
      !(browser.name.toLowerCase() === 'chrome');
  }

  /**
   * Check if game state exists and if it does display continue game modal.
   */
  handleButtonPress = (): void => {
    let existingState = this.getStateExists();
    if (existingState) {
      this.choiceVisible = true;
    } else {
      this.dispatchSelectionAction('newGame');
    }
  };

  /**
   * Fires the action based on the user's choice.
   * @param modalChoice The users choice whether to continue or start new.
   */
  dispatchSelectionAction(modalChoice: string) {
    this._store.dispatch(
      Actions.setPersonas({
        personas: this._json.readPersonasJSON(),
      })
    );
    this._store.dispatch(
      Actions.setExperience({
        experience: this._json.readExperienceJSON(),
      })
    );
    if (modalChoice === 'continue') {
      this._router.navigate(['/gamescreen']);
    } else {
      this._router.navigate(['/primer']);
    }
  }

  /**
   * Helper function for determining if a game state exists.
   * @returns If the state exists.
   */
  getStateExists = (): boolean => {
    if (this.history.length > 0) {
      return true;
    }
    return false;
  };

  newGameClick() {
    this._stateService.clearGameState();
    this.dispatchSelectionAction('newGame');
  }

  continueClick() {
    this.dispatchSelectionAction('continue');
    this._stateService.restoreGameState();
  }

  hideModal() {
    this.choiceVisible = false;
  }

  closeBrowserNotification() {
    this.showBrowserNotification = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < this.CONSTANT.TABLET_PORTRAIT_THRESHOLD) {
      this.isDesktop = false;
    } else {
      this.isDesktop = true;
    }
  }

  // Unsubscribing from subscriptions at the end
  ngOnDestroy(): void {
    this.listenerSubscription.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
