import { createAction, props } from '@ngrx/store';
import { Goal } from 'src/app/model/experiences/goal.model';
import { PushNotification } from 'src/app/model/experiences/push-notification.model';
import { Transaction } from 'src/app/model/experiences/transaction.model';

export const setMoney = createAction(
  '[Game Initialization] Set Money',
  props<{ money: number }>()
);

export const setaddMoney = createAction(
  '[Game Card] Set Add Money',
  props<{ money: number }>()
);

export const setremoveMoney = createAction(
  '[Game Card] Set Remove Money',
  props<{ money: number }>()
);

export const setTime = createAction(
  '[Screen Game] Set Time',
  props<{ time: number }>()
);

export const setaddTime = createAction(
  '[Screen Game] Set Add Time',
  props<{ time: number }>()
);

export const setremoveTime = createAction(
  '[Screen Game] Set Remove Time',
  props<{ time: number }>()
);

export const pushToNotificationStack = createAction(
  '[Screen Game] Push To Notification Stack',
  props<{ notification: PushNotification }>()
);

export const clearOldestFromNotificationStack = createAction(
  '[Screen Game] Clear Oldest From Notification Stack'
);

export const clearAllFromNotificationStack = createAction(
  '[Screen Game] Clear All From Notification Stack'
);

export const pushToTransactionStack = createAction(
  '[Screen Game] Push To Transaction Stack',
  props<{ transaction: Transaction }>()
);

export const clearOldestFromTransactionStack = createAction(
  '[Screen Game] Clear Oldest From Transaction Stack'
);

export const clearAllFromTransactionStack = createAction(
  '[Screen Game] Clear All From Transaction Stack'
);

export const clearAllGoals = createAction('[Screen Game] Clear All Goals');

export const addGoal = createAction(
  '[Screen Game] Add Goal To List',
  props<{ goal: Goal }>()
);

export const removeGoal = createAction(
  '[Screen Game] Remove Goal From List',
  props<{ id: number }>()
);

export const updateGoalToComplete = createAction(
  '[Screen Game] Update Goal To Complete Status',
  props<{ id: number }>()
);
