<div
  id="game-card-content"
  [@swipeCard]="animationEnabled ? gameCardAnimationState : ''"
>
  <div
    id="card-title"
    [ngClass]="
      gameCardContent === event?.reflection_card
        ? cardTypeClassSelector + '-background reflection-title'
        : cardTypeClassSelector + '-background'
    "
  >
    {{ event?.title }}
  </div>
  <sprk-stack id="card-body-stack" splitAt="large">
    <app-card-geoevent
      *ngIf="geoEvent && cardType == 'GEOEVENT'"
      [geoEvent]="geoEvent"
      (actionHandler)="handleAction($event)"
      class="geocard"
    ></app-card-geoevent>
    <ng-container
      *ngIf="cardType != CONSTANT.REFLECTION_CARD_TYPE && !geoEvent"
    >
      <app-card-description
        id="card-description"
        class="sprk-o-Stack__item--three-fifths@l card-body-stack-item"
        [cardContent]="gameCardContent?.content"
        (zoomImage)="zoomImage = true"
      ></app-card-description>

      <div class="backdrop" *ngIf="zoomImage" (click)="zoomImage = false"></div>
      <div class="content" *ngIf="zoomImage">
        <svg
          (click)="zoomImage = false"
          class="
            close-icon
            sprk-c-Icon
            sprk-c-Icon--lg
            sprk-c-Icon--stroke-current-color
            sprk-c-Icon--filled-current-color
          "
          viewBox="0 0 100 100"
        >
          <use xlink:href="#close" />
        </svg>
        <div class="content-inner" *ngIf="zoomImage">
          <img
            src="{{ gameCardContent?.content.multimedia.link }}"
            alt="Card Image"
            class="zommed-picture"
            (click)="imageZoom()"
          />
        </div>
      </div>
      <app-card-form
        id="card-form"
        class="sprk-o-Stack__item--two-fifths@l card-body-stack-item"
        [cardForm]="gameCardContent?.form"
        (handleAction)="handleAction($event)"
      >
      </app-card-form>
    </ng-container>

    <div
      *ngIf="cardType == CONSTANT.REFLECTION_CARD_TYPE && !geoEvent"
      class="reflection-card"
    >
      <div class="reflection-card-inner">
        <img
          src="{{ gameCardContent?.content.multimedia.link }}"
          alt="Reflection Image"
          class="reflection-image"
        />
        <div class="reflection-text-container">
          <p
            *ngFor="let text of gameCardContent?.content.text.text_blocks"
            class="reflection-text"
          >
            {{ text }}
          </p>
        </div>
        <app-button
          [buttonText]="gameCardContent?.content.button.text"
          [buttonType]="'button-primary'"
          class="reflection-card-button"
          (click)="handleAction(gameCardContent?.id)"
        >
        </app-button>
      </div>
    </div>
  </sprk-stack>
</div>
