import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { OnboardingItem } from 'src/app/model/experiences/onboarding-item.model';
import * as HistorySelectors from '../../store/selectors/history.selectors';
import { History } from '../../store/reducers';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-game-screen',
  templateUrl: './game-screen.component.html',
  styleUrls: ['./game-screen.component.scss'],
})
export class GameScreenComponent implements OnInit {
  listenerSubscription: Subscription[] = [];

  history: History[] = null;
  history$: Observable<History[]> = this._store?.pipe(
    select(HistorySelectors.selectHistoryStack)
  );

  constructor(
    private readonly _store: Store,
    private readonly _router: Router
  ) {
    this._router?.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        if (event.restoredState) {
          this._router.navigate(['home']);
        }
      });
  }

  activeItem: number = 0;
  showTutorial: boolean = false;

  onboardingItems: OnboardingItem[] = [
    {
      id: 0,
      text: 'In addition to information about your persona, you will have a few items to help you keep track of the day.',
      buttonText: 'Next',
      element: null,
    },
    {
      id: 1,
      text: 'Your goals today are to complete the night school application form, go grocery shopping, and then head to work early to speak to your manager. You only have a few hours before you have to start work at 4:30pm.',
      buttonText: 'Next',
      element: null,
    },
    {
      id: 2,
      text: 'It’s 1pm now, and you need to be at work by 4:30pm. Keep an eye on the time!',
      buttonText: 'Next',
      element: null,
    },
    {
      id: 3,
      text: 'This is your phone. Here you will receive important notifications. You can also see your spending and look back at the highlights of your persona profile.',
      buttonText: 'Next',
      element: null,
    },
    {
      id: 4,
      text: 'After researching some options while at the public library, you have decided to sign up for night classes. Your mom has offered to watch the kids at night. You just need to ask your employer to switch your afternoon shifts.',
      buttonText: "Let's Go!",
      element: null,
    },
  ];

  ngOnInit(): void {
    this.listenerSubscription.push(
      this.history$.subscribe((data) => (this.history = data))
    );
    if (this.history?.length === 1) this.showTutorial = true;

    const notepad: HTMLElement = document
      .querySelector('#sidebar')
      .querySelector('#goals-container');
    this.onboardingItems[1].element = notepad;
    const clock: HTMLElement = document
      .querySelector('#sidebar')
      .querySelector('#clock');
    this.onboardingItems[2].element = clock;
    const phone: HTMLElement = document.querySelector('#phone');
    this.onboardingItems[3].element = phone;
  }

  /**
   * @returns Which game element is currently highlighted on the screen.
   */
  getActiveItem(): OnboardingItem {
    return this.onboardingItems.find((item) => item.id === this.activeItem);
  }

  /**
   * Called to highlight the next game element in the list.
   */
  highlightNewItem(): void {
    this.onboardingItems
      .filter((item) => item.id != 0 && item.id != 4) // first and fourth items have no associated element
      .forEach((item) => {
        if (item.id === this.activeItem) {
          item.element.style.zIndex = '11';
        } else {
          item.element.style.zIndex = '0';
        }
      });
  }

  onCarouselClick(id: number): void {
    this.activeItem = id;
    this.highlightNewItem();
  }

  /**
   * Handler for onboarding button click. Progresses the mini tutorial.
   */
  handleClickEvent(): void {
    if (this.activeItem < this.onboardingItems.length - 1) {
      this.activeItem += 1;
      this.highlightNewItem();
    } else {
      this.highlightNewItem();
      this.showTutorial = false;
    }
  }

  // Unsubscribing from subscriptions at the end
  ngOnDestroy(): void {
    this.listenerSubscription.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
