<div id="main-container-body">
  <div id="statusbar">
    <app-mobile-header></app-mobile-header>
  </div>
  <div id="gamescreen">
    <sprk-stack id="gamescreen-stack" splitAt="tiny">
      <div id="sidebar" sprkStackItem class="sprk-o-Stack__item--fifth@xs">
        <app-sidebar></app-sidebar>
      </div>
      <div
        id="card-container"
        sprkStackItem
        sprkBox
        class="sprk-o-Stack__item--flex@xs"
      >
        <app-game-card></app-game-card>
      </div>
      <div
        id="phone"
        sprkStackItem
        sprkBox
        class="sprk-o-Stack__item--fifth@xs"
      >
        <app-phone></app-phone>
      </div>
    </sprk-stack>
  </div>
</div>

<div id="onboarding-overlay" *ngIf="showTutorial"></div>
<div id="onboarding-content-container" *ngIf="showTutorial">
  <div class="onboarding-content-row">
    <span class="onboarding-text">{{ getActiveItem().text }}</span>
  </div>
  <div class="onboarding-content-row">
    <div class="carousel-navigation">
      <app-carousel-buttons
        [items]="onboardingItems"
        [activeIndex]="activeItem"
        (handleAction)="onCarouselClick($event)"
      ></app-carousel-buttons>
    </div>
  </div>
  <div class="onboarding-content-row">
    <app-button
      class="onboarding-button"
      [buttonType]="'button-primary'"
      [buttonText]="getActiveItem().buttonText"
      (click)="handleClickEvent()"
    ></app-button>
  </div>
</div>
