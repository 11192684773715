<sprk-stack id="form-container">
  <app-card-table
    sprkStackItem
    *ngIf="cardForm?.table"
    [tableData]="cardForm?.table"
  ></app-card-table>
  <div sprkStackItem id="form">
    <div id="dropdown" *ngIf="cardForm?.dropdown">
      <form (ngSubmit)="handleFormClick(f)" #f="ngForm">
        <fieldset class="sprk-b-Fieldset">
          <legend id="dropdown-title" class="sprk-b-Legend">
            {{ cardForm?.dropdown.title }}
          </legend>
          <div
            class="dropdown-container"
            *ngFor="let field of cardForm?.dropdown.fields"
          >
            <div class="dropdown-label">{{ field?.field_title }}</div>
            <select
              [disabled]="field.disabled"
              id="{{ field.field_id }}"
              name="{{ field.field_id }}"
              [ngModel]="'dropdownDefault'"
              (change)="validateFormInputs($event, f)"
              [ngClass]="'options ' + cardTypeClassSelector + '-border'"
            >
              <option value="dropdownDefault" disabled selected hidden>
                - Please Select -
              </option>
              <ng-container *ngFor="let option of field?.options">
                <option
                  *ngIf="
                    field.field_id === 'COURSE_1'
                      ? option != selectedOption2
                      : option != selectedOption1
                  "
                  [ngValue]="option"
                >
                  {{ option }}
                </option>
              </ng-container>
            </select>
          </div>
        </fieldset>
        <div class="form-button-div">
          <app-button
            [isDisabled]="disableButton"
            [buttonText]="cardForm?.dropdown.submit_button_title"
            [buttonType]="'button-primary'"
            class="card-button"
          >
          </app-button>
        </div>
      </form>
    </div>
    <div id="radio" *ngIf="getShowRadio()">
      <form (ngSubmit)="handleRadioClick(f)" #f="ngForm">
        <h5 id="radio-form-title">{{ cardForm?.radio.title }}</h5>
        <div *ngFor="let option of radioOptions; index as i">
          <div class="radio-container" [tabindex]="i">
            <label
              for="{{ option.title }}"
              [ngClass]="cardTypeClassSelector + '-border'"
              class="label-radio"
            >
              <div class="radio-option">
                <input
                  type="radio"
                  class="radio-image"
                  id="{{ option.title }}"
                  name="radio"
                  [value]="option.on_click"
                  ngModel
                  required
                />
                <div class="radio-desc">
                  <span id="radio-title">{{ option?.title }}</span>
                  <span id="radio-text">{{ option?.text }}</span>
                </div>
              </div>
            </label>
          </div>
        </div>
        <div id="form-button-div">
          <app-button
            [buttonType]="'button-primary'"
            [buttonText]="cardForm.radio.button_title"
            [isDisabled]="!f.form.valid"
            class="card-button"
          >
          </app-button>
        </div>
      </form>
    </div>
    <div id="form-button-div" *ngIf="getShowContinue()">
      <div id="goals-and-transaction-container">
        <span class="transaction-notification" *ngIf="shouldShowTransaction()">
          You spent {{ transaction?.amount | currency }} on
          {{ transaction?.description }}.
        </span>
        <div class="goals-container">
          <span class="goals-header">Goals</span>
          <ol
            class="sprk-c-Stepper"
            data-sprk-stepper="container"
            data-id="stepper-1"
            role="tablist"
            aria-orientation="vertical"
          >
            <li
              *ngFor="let goal of goals; index as i"
              role="tab"
              class="sprk-c-Stepper__step"
              [ngClass]="getGoalItemClass(goal, i)"
              data-sprk-stepper="step"
            >
              <div class="sprk-c-Stepper__step-content">
                <span class="sprk-c-Stepper__step-header step-header-custom">
                  <span
                    class="sprk-c-Stepper__step-icon step-icon-custom"
                  ></span>
                  <span
                    class="text"
                    [ngClass]="goal.startingGoal ? 'starting-goal' : 'new-goal'"
                  >
                    {{ goal.text }}
                  </span>
                </span>
              </div>
            </li>
          </ol>
        </div>
      </div>
      <div id="fact" *ngIf="getShowFact()">
        <div id="fact-title">Did you know?</div>
        <div id="fact-text">{{ cardForm?.fact }}</div>
      </div>
      <app-button
        [buttonType]="'button-secondary'"
        [buttonText]="cardForm?.button.text"
        (click)="emitAction(getButtonAction())"
        class="card-button"
      ></app-button>
    </div>
  </div>
</sprk-stack>

<app-modal
  [displayModal]="showModal"
  [modalType]="CONSTANT.MODAL_PENALTY_TYPE"
  [title]="modalTitle"
  [text]="modalText"
  [ButtonText]="modalButton"
  (closeModalEvent)="handleModalClick()"
>
</app-modal>
