import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Content } from 'src/app/model';
import { AnswerResponse } from 'src/app/model/experiences/answer-response.model';
import { ButtonHandlerService } from 'src/app/services/handler/button-handler/button-handler.service';
import { ConstantsService } from 'src/app/statics/constants/constants.service';
import * as eventSelectors from '../../../store/selectors/event.selectors';

@Component({
  selector: 'app-card-description',
  templateUrl: './card-description.component.html',
  styleUrls: ['./card-description.component.scss'],
})
export class CardDescriptionComponent implements OnInit, OnChanges {
  @Input() cardContent: Content = null;
  @Output() zoomImage = new EventEmitter<void>();

  normalLink: string;
  normalText: string[];
  explanations: AnswerResponse[] = [];
  isImage: boolean = false;
  listenerSubscription: Subscription[] = [];

  cardType: string = null;
  cardType$: Observable<string> = this._store.pipe(
    select(eventSelectors.selectCardType)
  );

  constructor(
    private _formHandler: ButtonHandlerService,
    private readonly _store: Store,
    private CONSTANTS: ConstantsService
  ) {
    this.listenerSubscription.push(
      this.cardType$.subscribe((data) => (this.cardType = data))
    );
  }

  ngOnChanges() {
    this.explanations = this._formHandler.getAnswerConditions();
    if (this.cardType === this.CONSTANTS.DECISION_CARD_TYPE) {
      this.explanations.length = 0; // clear any answer explanation if current card is decision
    }
    // conditionally assign fields from experience json depending on card type
    switch (this.cardType) {
      case this.CONSTANTS.DECISION_CARD_TYPE:
        this.normalLink = this.cardContent.multimedia.link;
        this.normalText = this.cardContent.text.text_blocks;
        break;
      case this.CONSTANTS.CORRECT_RESULT:
        this.normalLink = this.cardContent.multimedia.link_correct;
        this.normalText = [this.cardContent.text.correct];
        break;
      case this.CONSTANTS.INCORRECT_RESULT:
        this.normalLink = this.cardContent.multimedia.link_incorrect;
        this.normalText = [this.cardContent.text.incorrect];
        break;
    }

    this.isImage = this.cardContent?.multimedia?.type === 'IMAGE';
    this.scrollToTop();
  }
  /**
   * Dispatches a signal to zoom in on card image.
   *
   * @param none
   */
  imageZoom() {
    if (this.cardContent?.multimedia?.expandable) {
      this.zoomImage.emit();
    }
  }

  /**
   * Returns whether the current card type is decision.
   *
   * @param none
   */
  getCardTypeIsDecision(): boolean {
    return this.cardType === this.CONSTANTS.DECISION_CARD_TYPE;
  }

  /**
   * Generates a classname for different card and media types.
   *
   * @param none
   */
  getImageClass(): string {
    let className = '';
    if (this.getCardTypeIsDecision()) {
      className += 'decision ';
    } else {
      className += 'result ';
    }

    if (this.cardContent?.multimedia?.expandable) {
      className += 'picture-stretch';
    } else {
      className += 'picture';
    }

    return className;
  }

  ngOnInit(): void {}

  /**
   * Scrolls text container up to top.
   *
   * @param none
   */
  scrollToTop(): void {
    document.getElementById('text-container').scrollTop = 0;
  }

  // Unsubscribing from subscriptions at the end
  ngOnDestroy(): void {
    this.listenerSubscription.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
