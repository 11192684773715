import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as L from 'leaflet';
import { Observable, Subscription } from 'rxjs';
import { Coordinates } from 'src/app/model/experiences/geoevent.model';
import MapProps, { MapCircle } from '../../model/map/map-props.model';
import * as geolocationSelector from '../../store/selectors/geolocation.selectors';
//get our arrows
import 'leaflet-arrowheads';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  private map!: L.Map;

  private userCircle: L.Circle;
  private arrows: L.Polyline;
  private arrowsSubscription: Subscription;

  position$: Observable<Coordinates> = this._store.pipe(
    select(geolocationSelector.selectPosition)
  );

  listenerSubscription: Subscription[] = [];

  @Input() mapProperties!: MapProps;
  @Input() debugMode!: boolean;
  @Input() position!: Coordinates;
  @Input() helpMeToggle!: boolean;

  constructor(private readonly _store: Store) {
    this._store = _store;
  }

  private initializeMap = (): void => {
    this.map = L.map('map', {
      center: [
        this.mapProperties?.center.latitude,
        this.mapProperties?.center.longitude,
      ],
      zoom: this.mapProperties?.zoomLevel,
      dragging: this.mapProperties?.enableControl,
      zoomControl: this.mapProperties?.enableControl,
      boxZoom: this.mapProperties?.enableControl,
      scrollWheelZoom: this.mapProperties?.enableControl,
      doubleClickZoom: this.mapProperties?.enableControl,
    });

    const tiles = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?',
      {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
      }
    );

    tiles.addTo(this.map);

    if (this.mapProperties?.circles?.length) {
      this.mapProperties?.circles.forEach((circle: MapCircle) => {
        let add = L.circle([circle.point.latitude, circle.point.longitude], {
          radius: circle.radius,
          fillColor: circle.fillColor,
          fill: true,
        });
        add.addTo(this.map);
      });
    }

    if (this.helpMeToggle) {
      this.helpMe();
    }

    //If we're in debug mode, enable live location updates.
    if (this.debugMode) {
      let destination = L.circle(
        [
          this.mapProperties.center.latitude,
          this.mapProperties.center.longitude,
        ],
        {
          radius: 1,
          fill: true,
          fillColor: 'green',
          color: 'green',
        }
      );
      destination.addTo(this.map);
      this.listenerSubscription.push(
        this.position$.subscribe((data) => {
          if (data) {
            //Create the user circle.
            if (this.userCircle) {
              this.map.removeLayer(this.userCircle);
            }
            this.userCircle = L.circle([data.latitude, data.longitude], {
              radius: 2,
              fillColor: 'red',
              color: 'red',
              fill: true,
            });
            this.userCircle.addTo(this.map);
          }
        })
      );
    }
  };

  ngOnInit(): void {
    this.initializeMap();
  }

  /**
   * Shows arrows pointing in the correct direction.
   */
  helpMe(): void {
    if (this.map && this.helpMeToggle && !this.arrows) {
      this.listenerSubscription.push(
        (this.arrowsSubscription = this.position$.subscribe((data) => {
          if (data && this.helpMeToggle) {
            //Create the arrows
            if (this.arrows) {
              this.map.removeLayer(this.arrows);
            }
            this.arrows = L.polyline([
              [data.latitude, data.longitude],
              [this.map.getCenter().lat, this.map.getCenter().lng],
            ])['arrowheads']({
              frequency: 'endonly',
              proportionalToTotal: false,
              size: '20px',
            });
            this.arrows.addTo(this.map);
          }
        }))
      );
    }
  }

  // Unsubscribing from subscriptions at the end
  ngOnDestroy(): void {
    this.listenerSubscription.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
