<div id="page" [ngClass]="currentChar === 0 ? 'mom' : 'dad'">
  <div
    [@bio]="isDesktop ? bioAnimationState : ''"
    [@bioMobile]="isDesktop ? '' : bioAnimationState"
    id="bio"
  >
    <div class="scrollable" id="scrollable">
      <div class="title">About {{ getPersonaName() }}</div>
      <div class="text">
        {{ getSelectedPersona()?.description.information }}
      </div>
      <div class="title">Background</div>
      <div class="text">
        {{ getSelectedPersona()?.description?.background[0] }}
      </div>
      <div class="text">
        {{ getSelectedPersona()?.description?.background[1] }}
      </div>
    </div>
    <div
      id="buttons"
      [@buttons]="isDesktop ? buttonsAnimationState : ''"
      [@buttonsMobile]="isDesktop ? '' : buttonsAnimationState"
    >
      <p
        (click)="handlePersonaChange(-1)"
        alt="Previous Character"
        class="nav-image"
      >
        <
      </p>
      <app-button
        class="button"
        [buttonType]="'button-primary'"
        [buttonText]="'Select'"
        (click)="handleNavigation()"
      >
      </app-button>
      <p
        (click)="handlePersonaChange(1)"
        alt="Previous Character"
        class="nav-image"
      >
        >
      </p>
    </div>
  </div>
</div>
