import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ConstantsService } from 'src/app/statics/constants/constants.service';
import * as eventSelectors from '../../store/selectors/event.selectors';
import * as geolocationActions from '../../store/actions/geolocation.actions';
import { Coordinates } from 'src/app/model/experiences/geoevent.model';
import { trigger } from '@angular/animations';

@Injectable({
  providedIn: 'root',
})
export default class GeolocationManager {
  constructor(
    private readonly _store: Store,
    private readonly CONSTANT: ConstantsService
  ) {
    this.cardType$.subscribe((data) => (this.cardType = data));
  }

  cardType: string;
  cardType$: Observable<string> = this._store?.pipe(
    select(eventSelectors.selectCardType)
  );

  private watchPositionId: number = null;

  public getWatchPositionId = (): number => {
    return this.watchPositionId;
  };

  /**
   *  setActive
   *
   *  This is (probably) called by the actionHandler upon transition to a geoevent. This simply calls the
   *  'reqeustLocation' method and sets the return value (id) to our 'watchPositionId' variable so we can unregister it later.
   */
  setActive = (): void => {
    if (this.cardType == this.CONSTANT?.GEOEVENT_CARD_TYPE) {
      if (!this.watchPositionId)
        this.requestLocation()
          .then((val) => {
            this.watchPositionId = val;
          })
          .catch((err) => {
            this.watchPositionId = null;
            //handle deny here
          });
    }
  };

  /**
   *  clearWatchers
   *
   *  Uses 'watchPositionId' to unregister the handler. This is probably called by the actionHandler upon
   *  transition to a non-geoevent so we don't read the user's location inbetween tasks.
   *
   */
  clearWatchers = (): void => {
    if (this.watchPositionId) {
      window.navigator.geolocation.clearWatch(this.watchPositionId);
      this.watchPositionId = null;
      //let's also clear the user's location data
      this._store.dispatch(
        geolocationActions.updateLocation({ position: null })
      );
    }
  };

  /**
   *  requestLocation
   *
   *  Makes a request to 'window.navigator.geolocation' to ask for the user's permission to read their location.
   *  The browser remembers the choice, so if the user allows it once, then it'll remember every time until
   *  they clear their cache. Works similarly if they deny it.
   * @returns number - The id of the watchPosition handler. Saved to 'this.watchPositionId' so we can unregister it later when the user isn't on a geoevent.
   */
  requestLocation = async (): Promise<number> => {
    return new Promise<number>((resolve, reject) => {
      window.navigator.geolocation.getCurrentPosition(
        (success) => {
          resolve(
            window.navigator.geolocation.watchPosition(
              this.onLocationUpdate,
              null,
              { enableHighAccuracy: true }
            )
          );
        },
        (failure) => {
          reject(null);
        }
      );
    });
  };

  /**
   * calculateDistance
   *
   *  Calculates the distance between a pair of coordinates and returns the value in meters.
   *
   * source: https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates
   *
   * @param pointA Coordinate - point A
   * @param pointB Coordinate - point B
   * @returns number - The distance between point A and point B in meters.
   */
  calculateDistance = (pointA: Coordinates, pointB: Coordinates): number => {
    if (
      !pointA?.latitude ||
      !pointA?.longitude ||
      !pointB?.latitude ||
      !pointB?.longitude
    )
      return null;
    var R = 6378.137; // Radius of earth in KM
    var dLat =
      (pointB.latitude * Math.PI) / 180 - (pointA.latitude * Math.PI) / 180;
    var dLon =
      (pointB.longitude * Math.PI) / 180 - (pointA.longitude * Math.PI) / 180;
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((pointA.latitude * Math.PI) / 180) *
        Math.cos((pointB.latitude * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d * 1000; // meters
  };

  /**
   *  onLocationUpdate
   *
   *  This is our position handler that the browser will fire whenever the user's position
   *  changes.
   *
   * @param position https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPosition
   */
  onLocationUpdate = (position: any): void => {
    this._store.dispatch(
      geolocationActions.updateLocation({
        position: {
          latitude: position['coords']['latitude'],
          longitude: position['coords']['longitude'],
        },
      })
    );
  };
}
