import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sentencecase' })
export class SentenceCasePipe implements PipeTransform {
  constructor() {}

  transform(value: string): string {
      let sentence = value.trim();
      let firstLetter = sentence.substr(0,1).toUpperCase();
      return firstLetter + sentence.substr(1);
  }
}
