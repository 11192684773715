import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Experience, Persona } from 'src/app/model';
import * as experienceData from '../../../assets/json-files/experiences/west-windsor-jane-doe.json';
import * as personaData from '../../../assets/json-files/personas/west-windsor-personas.json';

@Injectable({
  providedIn: 'root',
})
export class ReadJSONService {
  experience: Experience;

  constructor(private _http: HttpClient) {}

  /**
   * @returns JSON object of persona data.
   */
  public readPersonasJSON() {
    let jsonObject: Persona[] = JSON.parse(JSON.stringify(personaData));
    return jsonObject['default'];
  }

  /**
   * @returns JSON objecof experience data.
   */
  public readExperienceJSON() {
    let jsonObject: Experience = JSON.parse(JSON.stringify(experienceData));
    return jsonObject['default'];
  }
}
