<div id="phoneContainer">
  <div id="phoneInnerContainer" class="background">
    <img
      src="../../../../assets/images/icons/phone.png"
      id="phoneInnerContainerImage"
    />
    <div id="backgroundOverlay">
      <div id="phoneColumn">
        <div id="phoneClockContainer">
          <span class="phoneClock"
            >{{ hours | doubledigit }}:{{ minutes | doubledigit }}</span
          >
          <span class="phoneClock">{{ currentDate }}</span>
        </div>
        <div id="notificationContainer">
          <ng-container *ngFor="let notification of notifications">
            <app-phone-notification
              [notification]="notification"
            ></app-phone-notification>
          </ng-container>
        </div>
      </div>
    </div>
    <div id="phoneWidgetContainer">
      <div id="phoneWidgetFlexBox">
        <div id="widgetMainContainer">
          <div *ngIf="tabs.balance" class="tab">
            <div id="widgetTopComponent">
              <div id="widgetComponentInner">
                <div id="widgetComponentInnerSplit">
                  <div id="widgetContainerRow">
                    <div id="balanceLeftContainer">
                      <div id="balanceLeftContainerContent">
                        <sprk-icon
                          iconType="amount-money "
                          additionalClasses="sprk-c-Icon--xl sprk-c-Icon--filled-current-color"
                        ></sprk-icon>
                        <span
                          id="balanceValue"
                          [ngStyle]="{
                            color:
                              getRemainingBalance() < 0 ? '#f7022a' : '#2adf00'
                          }"
                          >{{ getRemainingBalance() | currency }}</span
                        >
                      </div>
                    </div>
                    <div id="balanceRightContainer">
                      <div id="rightContainerRow">
                        <span id="rightHeaderText">Transactions</span>
                      </div>
                      <ng-container *ngFor="let transaction of transactions">
                        <div id="transactionItem">
                          <div id="balanceRightValueItem">
                            <span
                              id="balanceRightValue"
                              [ngStyle]="{
                                color: transaction?.negative
                                  ? '#ff2d2d'
                                  : '#2adf00'
                              }"
                              >{{
                                transaction?.negative === true ? "-" : "&nbsp;"
                              }}{{ transaction?.amount | currency }}</span
                            >
                          </div>
                          <div id="balanceRightDescriptionItem">
                            <span id="balanceRightDescription"
                              >{{ transaction?.description }}
                            </span>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="tabs.profile" class="tab">
            <div id="widgetTopComponent">
              <div id="widgetComponentInner">
                <div id="widgetComponentInnerSplit">
                  <div id="widgetContainerRow">
                    <div id="profileImageContainer">
                      <img [src]="persona?.profile_image" id="profileImage" />
                    </div>
                    <div id="profileRightContainer">
                      <div id="rightContainerRow">
                        <span id="rightHeaderText"
                          >{{ persona?.first_name }}</span
                        >
                      </div>
                      <div id="profileContainer">
                        <ul
                          id="profileSummary"
                          *ngFor="let point of persona?.phone_summary"
                        >
                          <li id="list-point">{{ point }}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="tabbar">
          <div id="tabbarWrapper">
            <button
              (click)="handleWidgetClick('balance')"
              id="tabbarButton"
              [ngStyle]="styleTabs('balance')"
            >
              <sprk-icon
                iconType="payment-filled"
                additionalClasses="sprk-c-Icon--s sprk-c-Icon--filled-current-color"
                style="display: flex"
              >
              </sprk-icon>
              <span id="tabbarText" [ngStyle]="styleTabs('balance')"
                >Balance</span
              >
            </button>
            <div id="tabbarDivider"></div>
            <button
              (click)="handleWidgetClick('profile')"
              id="tabbarButton"
              [ngStyle]="styleTabs('profile')"
            >
              <sprk-icon
                iconType="single-filled"
                additionalClasses="sprk-c-Icon--s sprk-c-Icon--filled-current-color"
                style="display: flex"
              >
              </sprk-icon>
              <span id="tabbarText" [ngStyle]="styleTabs('profile')"
                >Profile</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
