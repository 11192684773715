import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import GeoEvent, {
  Coordinates,
} from 'src/app/model/experiences/geoevent.model';
import GeolocationManager from 'src/app/services/geo-event/geo-event.service';
import * as geolocationSelector from '../../../store/selectors/geolocation.selectors';

@Component({
  selector: 'app-card-geoevent',
  templateUrl: './card-geo-event.component.html',
  styleUrls: ['./card-geo-event.component.scss'],
})
export class CardGeoEventComponent implements OnInit {
  @Input() geoEvent: GeoEvent;
  @Output() actionHandler: EventEmitter<number> = new EventEmitter();

  position$: Observable<Coordinates> = this._store.pipe(
    select(geolocationSelector.selectPosition)
  );

  showMap: boolean = false;
  showModal: boolean = false;
  listenerSubscription: Subscription[] = [];

  @Input() helpMeToggle: boolean = false;

  //Enable to see the destination, its radius, and the user's detected position on the map component.
  //Make sure this is FALSE before committing.
  debugMode: boolean = false;

  constructor(
    private readonly _store: Store,
    private readonly _geoloc: GeolocationManager
  ) {}

  ngOnInit(): void {
    this.listenerSubscription.push(
      this.position$.subscribe((data) => {
        if (this.geoEvent) {
          if (data) {
            if (
              this._geoloc.calculateDistance(data, this.geoEvent.coordinates) <
              this.geoEvent.radius
            ) {
              this.actionHandler.emit(this.geoEvent.next_action);
            }
          }
        }
      })
    );
  }

  mapClicked(): void {
    this.showMap = !this.showMap;
  }

  handleClickEvent(): void {
    this.showModal = true;
  }

  confirmModal(e) {
    this.actionHandler.emit(this.geoEvent.next_action);
  }

  cancelModal(e) {
    this.showModal = false;
  }

  helpMeClick() {
    this.showMap = !this.showMap;
    this.helpMeToggle = !this.helpMeToggle;
  }

  // Unsubscribing from subscriptions at the end
  ngOnDestroy(): void {
    this.listenerSubscription.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
