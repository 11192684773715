import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Experience } from 'src/app/model';

import * as experienceSelectors from '../../../store/selectors/experience.selectors';
import * as eventSelectors from '../../../store/selectors/event.selectors';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  constructor(private readonly _store: Store) {}

  progress: number = 0;
  remaining: number = 0;
  totalEventIds: number[];
  listenerSubscription: Subscription[] = [];

  eventId: number = null;
  eventId$: Observable<number> = this._store.pipe(
    select(eventSelectors.selectEventId)
  );

  experience: Experience = null;
  experience$: Observable<Experience> = this._store.pipe(
    select(experienceSelectors.selectExperience)
  );

  ngOnInit(): void {
    this.listenerSubscription.push(
      this.experience$.subscribe((exp) => {
        this.totalEventIds = exp?.events.map((event) => event.id);
      })
    );
    this.listenerSubscription.push(
      this.eventId$.subscribe((newEventId) => {
        // When the event id updates, recalculate the total game progress.
        let completedIndex = this.totalEventIds?.findIndex(
          (eventId) => eventId === newEventId
        );
        this.progress =
          (completedIndex / (this.totalEventIds?.length - 1)) * 100; // -1 because the last card is the reflection card
        this.remaining = 100 - this.progress;
      })
    );
  }

  // Unsubscribing from subscriptions at the end
  ngOnDestroy(): void {
    this.listenerSubscription.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
