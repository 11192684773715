import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as EventActions from '../../../store/actions/event.actions';
import * as HistoryActions from '../../../store/actions/history.actions';
import { ResourceHandlerService } from '../resource-handler/resource-handler.service';
import { ButtonHandlerService } from '../button-handler/button-handler.service';
import { GameResource } from 'src/app/store/reducers';
import * as resourceActions from '../../../store/actions/game-resource.actions';
import { Transaction } from 'src/app/model/experiences/transaction.model';
import { PushNotification } from 'src/app/model/experiences/push-notification.model';
import { Goal } from 'src/app/model/experiences/goal.model';
import * as experienceSelectors from '../../../store/selectors/experience.selectors';
import * as eventSelectors from '../../../store/selectors/event.selectors';
import * as resourceSelector from '../../../store/selectors/game-resource.selectors';
import * as modeSelector from '../../../store/selectors/mode.selectors';
import { selectResource } from 'src/app/store/selectors/game-resource.selectors';
import { Observable } from 'rxjs';
import { Experience } from 'src/app/model/experiences/experience.model';
import { ConstantsService } from 'src/app/statics/constants/constants.service';
import GeolocationManager from '../../geo-event/geo-event.service';
import GeoEvent from 'src/app/model/experiences/geoevent.model';

@Injectable({
  providedIn: 'root',
})
export class ActionHandlerService {
  experience: Experience = null;
  experience$: Observable<Experience> = this._store.pipe(
    select(experienceSelectors.selectExperience)
  );

  mode: string = null;
  mode$ = this._store.pipe(select(modeSelector.selectMode));

  cardType: string = null;
  cardType$: Observable<string> = this._store.pipe(
    select(eventSelectors.selectCardType)
  );

  eventId: number = null;
  eventId$: Observable<number> = this._store.pipe(
    select(eventSelectors.selectEventId)
  );

  gameResource: GameResource = null;
  gameResource$: Observable<GameResource> = this._store.pipe(
    select(selectResource)
  );

  pushNotifications$: Observable<PushNotification[]> = this._store.pipe(
    select(resourceSelector.selectPushNotifications)
  );
  pushNotifications: PushNotification[] = [];
  transactions$: Observable<Transaction[]> = this._store.pipe(
    select(resourceSelector.selectTransactions)
  );
  transactions: Transaction[] = [];

  goals: Goal[] = null;
  goals$: Observable<Goal[]> = this._store.pipe(
    select(resourceSelector.selectGoals)
  );

  constructor(
    private readonly _store: Store,
    private _resource: ResourceHandlerService,
    private _formHandler: ButtonHandlerService,
    private CONSTANT: ConstantsService,
    private readonly _geoloc: GeolocationManager
  ) {
    this.experience$.subscribe((data) => (this.experience = data));
    this.eventId$.subscribe((data) => (this.eventId = data));
    this.gameResource$.subscribe((data) => (this.gameResource = data));
    this.cardType$.subscribe((data) => (this.cardType = data));
    this.pushNotifications$.subscribe(
      (data) => (this.pushNotifications = data)
    );
    this.transactions$.subscribe((data) => (this.transactions = data));
    this.mode$.subscribe((data) => (this.mode = data));
    this.goals$.subscribe((data) => (this.goals = data));
  }

  /**
   * Dispatches store events based on action numbers.
   * @param actionNum Action number
   * @param currentEventId The id of the current game event
   * @param time Remaining game time
   */

  actionHandler(actionNum: number, currentEventId: number, time: string) {
    const identifiedAction = this.experience?.actions.find(
      (a) => a.id === actionNum
    );

    if (!identifiedAction) return;

    switch (identifiedAction.type) {
      case 'TO_GEOEVENT':
        //If we're in the 'IN_PERSON' mode, then we'll dispatch these cardtypes, and enable the geolocation watcher.
        if (this.mode == this.CONSTANT.IN_PERSON_EXP) {
          this._store.dispatch(
            EventActions.setEventCardType({
              cardType: this.CONSTANT.GEOEVENT_CARD_TYPE,
            })
          );
          //dispatch the thingy that sets the geolocation data
          this._store.dispatch(
            EventActions.setEventGeoData({
              geoEvent:
                this.experience.events[identifiedAction.event_id].geo_event,
            })
          );

          //Also, tell the geolocation manager to become active again.
          this._geoloc.setActive();
        }
        //otherwise, we're not doing anything with this action, so we wont do anything more here.
        break;
      case 'TO_DECISION':
        this._store.dispatch(
          EventActions.setEventCardType({
            cardType: this.CONSTANT.DECISION_CARD_TYPE,
          })
        );
        break;
      case 'TO_CORRECT_RESULT':
        this._store.dispatch(
          EventActions.setEventCardType({
            cardType: this.CONSTANT.CORRECT_RESULT,
          })
        );
        break;
      case 'TO_INCORRECT_RESULT':
        this._store.dispatch(
          EventActions.setEventCardType({
            cardType: this.CONSTANT.INCORRECT_RESULT,
          })
        );
        break;
      case 'TO_REFLECTION':
        this._store.dispatch(
          EventActions.setEventCardType({
            cardType: this.CONSTANT.REFLECTION_CARD_TYPE,
          })
        );
        break;
      case 'MONEY_PENALTY':
        this._resource.removeMoney(identifiedAction.amount);
        this._store.dispatch(
          EventActions.setEventCardType({
            cardType: this.CONSTANT.DECISION_CARD_TYPE,
          })
        );
        break;
    }

    // if time expended exists on current event, apply it
    if (identifiedAction?.time_expended) {
      this._resource.addTime(identifiedAction.time_expended);
    }

    this._store.dispatch(
      EventActions.setEventId({ eventId: identifiedAction.event_id })
    );

    if (identifiedAction?.notification_id >= 0)
      this.handleNotifications(identifiedAction.notification_id, time);

    if (identifiedAction?.transaction_id >= 0)
      this.handleTransactions(identifiedAction.transaction_id);

    if (identifiedAction?.complete_goal >= 0)
      this.handleCompleteGoals(identifiedAction.complete_goal);

    //Since Geo Events have goals on their own card, we'll handle adding the goals here
    //so we don't clutter up game-card component.
    if (identifiedAction.type === 'TO_GEOEVENT') {
      this.handleGeoAction(
        this.experience.events[this.eventId].geo_event,
        this.eventId,
        time
      );
    } else {
      //Stop watching the user's location
      this._geoloc.clearWatchers();
      //and clear the geoevent.
      this._store.dispatch(EventActions.setEventGeoData({ geoEvent: null }));
    }

    if (this._formHandler.getFormInput()) {
      this._store.dispatch(
        HistoryActions.setCardDesc({
          history: {
            cardNo: currentEventId,
            actionNo: actionNum,
            formData: this._formHandler.getFormInput(),
            gameResource: this.gameResource,
            cardType: this.cardType,
          },
        })
      );
    } else {
      this._store.dispatch(
        HistoryActions.setCardDesc({
          history: {
            cardNo: currentEventId,
            actionNo: actionNum,
            gameResource: this.gameResource,
            cardType: this.cardType,
          },
        })
      );
    }
  }

  /**
   * handleGeoAction
   *
   * Handles the geoevent action. Takes care of adding the goals that are on the geoevent,
   * as well as skipping them if we're in the virtual experience to provide a seamless experience
   * to both modes through the same JSON.
   *
   * @param geoEvent
   * @param currentEventId
   * @param time
   */
  private handleGeoAction = (
    geoEvent: GeoEvent,
    currentEventId: number,
    time: string
  ): void => {
    //now let's add the goals:
    geoEvent?.add_goal?.forEach((goal: number) => {
      let goalToDispatch: Goal = this.experience.goals.find(
        (g) => g.id === goal
      );
      this._store.dispatch(resourceActions.addGoal({ goal: goalToDispatch }));
    });
    //now we'll check to see if we're in virtual experience and if we should skip this action.
    if (this.mode === this.CONSTANT.VIRTUAL_EXP)
      this.actionHandler(geoEvent.next_action, currentEventId, time);
  };

  /**
   * handleNotifications
   *
   * Dispatches notifications for the identified action.
   *
   * @param notification_id number- the notification to dispatch an event for
   * @param time string - the time
   */
  private handleNotifications = (
    notification_id: number,
    time: string
  ): void => {
    let notificationToDispatch: PushNotification =
      this.experience.notifications.find((n) => n.id === notification_id);
    if (
      notificationToDispatch &&
      !this.pushNotifications.some((n) => n.id === notificationToDispatch.id)
    ) {
      notificationToDispatch = {
        ...notificationToDispatch,
        time: time,
      };
      this._store.dispatch(
        resourceActions.pushToNotificationStack({
          notification: notificationToDispatch,
        })
      );
    }
  };

  /**
   * handleCompleteGoals
   *
   * Dispatches complete goal events for the provided goal.
   *
   * @param complete_goal number - the goal to complete
   */
  private handleCompleteGoals = (complete_goal: number): void => {
    let goalToDispatch: Goal = this.experience.goals.find(
      (g) => g.id === complete_goal
    );
    if (goalToDispatch) {
      this._store.dispatch(
        resourceActions.updateGoalToComplete({ id: goalToDispatch.id })
      );
    }
  };

  /**
   * handleTransactions
   *
   * Handles the dispatching the transaction event for the identified action.
   *
   * @param transaction_id number - the transaction ID to dispatch an event for
   */
  private handleTransactions = (transaction_id: number): void => {
    const transactionToDispatch: Transaction =
      this.experience.transactions.find((t) => t.id === transaction_id);
    if (
      transactionToDispatch &&
      !this.transactions.some((n) => n.id === transactionToDispatch.id)
    ) {
      if (transactionToDispatch.negative) {
        this._store.dispatch(
          resourceActions.setremoveMoney({
            money: Number(transactionToDispatch.amount),
          })
        );
      } else {
        this._store.dispatch(
          resourceActions.setaddMoney({
            money: Number(transactionToDispatch.amount),
          })
        );
      }
      this._store.dispatch(
        resourceActions.pushToTransactionStack({
          transaction: transactionToDispatch,
        })
      );
    }
  };
}
