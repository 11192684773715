import { createReducer, on } from '@ngrx/store';
import { GeolocationState } from '.';
import * as EventActions from '../actions/geolocation.actions';

export const initialState: GeolocationState = {
  position: null,
};

export const GeolocationReducer = createReducer(
  initialState,
  on(EventActions.updateLocation, (state: GeolocationState, { position }) =>
    Object.assign({}, state, { position: position })
  )
);
