import { ActionReducerMap, ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from '../../../environments/environment';
import { ModeReducer } from './mode.reducer';
import { LocationReducer } from './location.reducer';
import { PersonaReducer } from './persona.reducer';
import { ExperienceReducer } from './experience.reducer';
import { GameResourceReducer } from './game-resource.reducer';
import { Persona } from 'src/app/model/personas/persona.model';
import { Experience } from 'src/app/model/experiences/experience.model';
import { EventReducer } from './event.reducer';
import { HistoryReducer } from './history.reducer';
import { FormReducer } from './form.reducer';
import { Transaction } from 'src/app/model/experiences/transaction.model';
import { PushNotification } from 'src/app/model/experiences/push-notification.model';
import { Goal } from 'src/app/model/experiences/goal.model';
import GeoEvent, {
  Coordinates,
} from 'src/app/model/experiences/geoevent.model';
import { GeolocationReducer } from './geolocation.reducer';

export interface State {
  mode: string;
  location: object;
  personaState: PersonaState;
  experienceState: ExperienceState;
  eventState: EventState;
  gameResource: GameResource;
  history: History[];
  formInput: object;
  geolocation: GeolocationState;
}

export interface PersonaState {
  personas: Persona[];
  selectedPersonaId: number;
}

export interface ExperienceState {
  experience: Experience;
}

export interface EventState {
  eventId: number;
  cardType: string;
  geoEvent: GeoEvent;
}

export interface GameResource {
  money: number;
  time: number;
  pushNotifications: PushNotification[];
  transactions: Transaction[];
  goals: Goal[];
}

export interface History {
  cardNo: number;
  actionNo: number;
  formData?: object;
  gameResource?: GameResource;
  cardType?: string;
}

export interface GeolocationState {
  position: Coordinates;
}

export const reducers: ActionReducerMap<State> = {
  mode: ModeReducer,
  location: LocationReducer,
  personaState: PersonaReducer,
  experienceState: ExperienceReducer,
  eventState: EventReducer,
  gameResource: GameResourceReducer,
  history: HistoryReducer,
  formInput: FormReducer,
  geolocation: GeolocationReducer,
};

function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [
      { mode: {} },
      { location: {} },
      { personaState: {} },
      { experienceState: {} },
      { eventState: {} },
      { gameResource: {} },
      { history: {} },
      { geolocation: {} },
    ],
    rehydrate: true,
  })(reducer);
}

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [localStorageSyncReducer]
  : [localStorageSyncReducer];
