import { AfterViewInit, Component, OnInit, HostListener } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as Actions from '../../store/actions/index';
import * as Selectors from '../../store/selectors/index';
import { ConstantsService } from '../../statics/constants/constants.service';
import { ActionHandlerService } from '../../services/handler/action-handler/action-handler.service';
import { History } from '../../store/reducers';
import { GameResource } from 'src/app/store/reducers';
import { StateHandlerService } from 'src/app/services/handler/state-handler/state-handler.service';
import { Router } from '@angular/router';
import {
  Event,
  Experience,
  Transaction,
  Card,
  Goal,
  PushNotification,
} from 'src/app/model';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import GeoEvent from 'src/app/model/experiences/geoevent.model';

enum AnimationStates {
  None = 'none',
  Leaving = 'leaving',
  Entering = 'entering',
}

const transitionAnimationLength = 0.7;

@Component({
  selector: 'app-game-card',
  templateUrl: './game-card.component.html',
  styleUrls: ['./game-card.component.scss'],
  animations: [
    trigger('mobileSwitch', [
      transition('entering => none', animate('500ms 0s ease-in')),
      state('none', style({ transform: 'translateY(0)', opacity: 1 })),
      state('entering', style({ transform: 'translateY(100%)', opacity: 0 })),
    ]),
    trigger('swipeCard', [
      transition(
        'entering => none',
        animate(`${transitionAnimationLength}s 0s ease-in-out`)
      ),
      transition(
        'none => leaving',
        animate(`${transitionAnimationLength}s 0s ease-in-out`)
      ),
      state('none', style({ transform: 'translateY(0)', opacity: 1 })),
      state('entering', style({ transform: 'translateY(8%)', opacity: 0 })),
      state('leaving', style({ transform: 'translateY(-8%)', opacity: 0 })),
    ]),
  ],
})
export class GameCardComponent implements OnInit, AfterViewInit {
  event: Event = null;
  gameCardContent: Card;
  hasOptions: boolean = null;
  cardTypeClassSelector: string = '';
  animationEnabled: boolean = true;
  zoomImage: boolean = false;
  listenerSubscription: Subscription[] = [];

  cardType: string = null;
  cardType$: Observable<string> = this._store?.pipe(
    select(Selectors.selectCardType)
  );
  eventId: number = null;
  eventId$: Observable<number> = this._store?.pipe(
    select(Selectors.selectEventId)
  );
  history: History[] = null;
  history$: Observable<History[]> = this._store?.pipe(
    select(Selectors.selectHistoryStack)
  );
  experience: Experience = null;
  experience$: Observable<Experience> = this._store?.pipe(
    select(Selectors.selectExperience)
  );
  gameResource: GameResource = null;
  gameResource$: Observable<GameResource> = this._store?.pipe(
    select(Selectors.selectResource)
  );
  hours: number = 0;
  minutes: number = 0;
  seconds: number = 0;
  totalSeconds: number = null;
  totalSeconds$: Observable<number> = this._store?.pipe(
    select(Selectors.selectTime)
  );
  pushNotifications: PushNotification[] = [];
  pushNotifications$: Observable<PushNotification[]> = this._store?.pipe(
    select(Selectors.selectPushNotifications)
  );
  transactions: Transaction[] = [];
  transactions$: Observable<Transaction[]> = this._store?.pipe(
    select(Selectors.selectTransactions)
  );
  money: number = null;
  money$: Observable<number> = this._store?.pipe(select(Selectors.selectMoney));
  goals: Goal[] = null;
  goals$: Observable<Goal[]> = this._store?.pipe(select(Selectors.selectGoals));
  gameCardAnimationState: AnimationStates = AnimationStates.Entering;

  geoEvent: GeoEvent = null;
  geoEvent$: Observable<GeoEvent> = this._store.pipe(
    select(Selectors.selectGeoEventData)
  );

  reflectionList: Goal[] = [];
  retriable: boolean;
  mode: string = null;
  mode$: Observable<string> = this._store.pipe(select(Selectors.selectMode));

  constructor(
    readonly CONSTANT: ConstantsService,
    private readonly _store: Store,
    private _action: ActionHandlerService,
    private _state: StateHandlerService,
    private _router: Router
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.gameCardAnimationState = AnimationStates.None;
    });
  }

  ngOnInit(): void {
    this.listenerSubscription.push(
      this.experience$.subscribe((data) => {
        this.experience = data;
      })
    );
    this.listenerSubscription.push(
      this.eventId$.subscribe((data) => (this.eventId = data))
    );
    this.listenerSubscription.push(
      this.cardType$.subscribe((data) => (this.cardType = data))
    );
    this.listenerSubscription.push(
      this.gameResource$.subscribe((data) => (this.gameResource = data))
    );
    this.listenerSubscription.push(
      this.geoEvent$.subscribe((data) => (this.geoEvent = data))
    );
    this.listenerSubscription.push(
      this.pushNotifications$.subscribe(
        (data) => (this.pushNotifications = data)
      )
    );
    this.listenerSubscription.push(
      this.transactions$.subscribe((data) => (this.transactions = data))
    );
    this.listenerSubscription.push(
      this.totalSeconds$.subscribe((data) => {
        this.totalSeconds = data;
        this.hours = Math.floor(this.totalSeconds / 3600) % 12;
        this.totalSeconds %= 3600;
        this.minutes = Math.floor(this.totalSeconds / 60);
        this.seconds = this.totalSeconds % 60;
      })
    );
    this.listenerSubscription.push(
      this.money$.subscribe((data) => (this.money = data))
    );
    this.listenerSubscription.push(
      this.goals$.subscribe((data) => (this.goals = data))
    );
    this.listenerSubscription.push(
      this.mode$.subscribe((data) => (this.mode = data))
    );
    this.listenerSubscription.push(
      this.history$.subscribe((data) => (this.history = data))
    );

    // With the new actionhandler flow, we push an action to the history on load
    // so the history's length will be 1 if its a new game. If it isn't a new game
    // ie: user continues, then the length will be the new action + old history which
    // should always be greater than 1.
    if (this.history?.length === 1) {
      this._store?.dispatch(Actions.setTime({ time: 3600 }));
      this._store?.dispatch(
        Actions.setEventId({ eventId: this.experience.entry_card })
      );
      this._store?.dispatch(Actions.setCard({ history: null }));
      this._store?.dispatch(
        Actions.setMoney({
          money: this.experience.starting_resources.money,
        })
      );
    }

    this.cardInit();
  }

  /**
   * Method that initializes card content. Called for each rerender.
   */
  cardInit(): void {
    let mainBody = document.getElementById('card-body-stack');
    if (mainBody) mainBody.scrollTop = 0; // to ensure the position of scrollbar to be always at the top while switching between the cards

    if (window.innerWidth < this.CONSTANT.TABLET_PORTRAIT_THRESHOLD) {
      this.animationEnabled = false;
    } else {
      this.animationEnabled = true;
    }
    if (this.pushNotifications?.length > 3) {
      this._store?.dispatch(Actions.clearOldestFromNotificationStack());
    }
    this.event = this.experience?.events?.find(
      (event) => event.id === this.eventId
    );
    this.hasOptions = this.event?.decision_card?.form.button.type === 'EXPAND';

    switch (this.cardType) {
      case this.CONSTANT.DECISION_CARD_TYPE:
        this.cardTypeClassSelector = 'decision-card';
        this.gameCardContent = this.event?.decision_card;
        this.retriable = this.event?.decision_card.retriable;
        break;
      case this.CONSTANT.CORRECT_RESULT:
        this.cardTypeClassSelector = 'correct-result-card';
        this.gameCardContent = this.event?.result_card;
        break;
      case this.CONSTANT.INCORRECT_RESULT:
        this.cardTypeClassSelector = 'incorrect-result-card';
        this.gameCardContent = this.event?.result_card;
        break;
      case this.CONSTANT.REFLECTION_CARD_TYPE:
        this.gameCardContent = this.event?.reflection_card;
        break;
      case this.CONSTANT.GEOEVENT_CARD_TYPE:
        // no game card content to read
        this.cardTypeClassSelector = 'geoevent-card';
        this.gameCardContent = null;
        break;
      default:
        this.gameCardContent = null;
    }

    // Add goals
    if (
      this.gameCardContent?.add_goal?.length &&
      !(this.retriable && this.cardType === this.CONSTANT.INCORRECT_RESULT)
    ) {
      this.gameCardContent.add_goal.forEach((goal) => {
        const goalToAdd: Goal = this.experience.goals.find(
          (g) => g.id === goal
        );
        if (goalToAdd && !this.goals.some((g) => g.id === goal)) {
          this._store?.dispatch(Actions.addGoal({ goal: goalToAdd }));
        }
      });
    }

    // Add transactions
    if (this.transactions?.length === 0) {
      this._store?.dispatch(
        Actions.pushToTransactionStack({
          transaction: {
            description: 'Starting balance',
            amount: 50,
            negative: false,
          },
        })
      );
    }
  }

  /**
   * Handles actions that are triggered by the buttons.
   * @param num Action number to trigger.
   */
  handleAction(num: number) {
    let isLastEvent =
      this.experience?.events[this.experience?.events?.length - 1].id ===
      this.eventId;
    if (this.cardType === this.CONSTANT.REFLECTION_CARD_TYPE && isLastEvent) {
      this._state.clearGameState();
      this._router.navigate(['/offboarding']);
    } else {
      this.gameCardAnimationState = AnimationStates.Leaving;
      setTimeout(() => {
        this.gameCardAnimationState = AnimationStates.Entering;
      }, transitionAnimationLength * 1000);
      const time = `${this.hours}:${
        this.minutes < 10 ? '0' + this.minutes : this.minutes
      } PM`;
      setTimeout(() => {
        this._action.actionHandler(num, this.eventId, time);
        this.cardInit();
      }, transitionAnimationLength * 1000);
      setTimeout(() => {
        this.gameCardAnimationState = AnimationStates.None;
      }, transitionAnimationLength * 1100);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth < this.CONSTANT.TABLET_PORTRAIT_THRESHOLD) {
      this.animationEnabled = false;
    } else {
      this.animationEnabled = true;
    }
  }

  // Unsubscribing from subscriptions at the end
  ngOnDestroy(): void {
    this.listenerSubscription.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
