import { GameResource } from './index';
import { createReducer, on } from '@ngrx/store';
import * as ResourceActions from '../actions/game-resource.actions';
import { PushNotification } from 'src/app/model/experiences/push-notification.model';
import { Transaction } from 'src/app/model/experiences/transaction.model';
import { Goal } from 'src/app/model/experiences/goal.model';

export const initialState: GameResource = {
  money: null,
  time: 0,
  pushNotifications: new Array<PushNotification>(),
  transactions: new Array<Transaction>(),
  goals: new Array<Goal>(),
};

export const GameResourceReducer = createReducer(
  initialState,
  on(ResourceActions.setMoney, (state: GameResource, { money }) =>
    Object.assign({}, state, { money: money })
  ),
  on(ResourceActions.setaddMoney, (state: GameResource, { money }) =>
    Object.assign({}, state, { money: state.money + money })
  ),
  on(ResourceActions.setremoveMoney, (state: GameResource, { money }) =>
    Object.assign({}, state, { money: state.money - money })
  ),
  on(ResourceActions.setTime, (state: GameResource, { time }) =>
    Object.assign({}, state, { time: time })
  ),
  on(ResourceActions.setaddTime, (state: GameResource, { time }) =>
    Object.assign({}, state, { time: state.time + time })
  ),
  on(ResourceActions.setremoveTime, (state: GameResource, { time }) =>
    Object.assign({}, state, { time: state.time - time })
  ),
  on(
    ResourceActions.pushToNotificationStack,
    (state: GameResource, { notification }) =>
      Object.assign({}, state, {
        pushNotifications: [{ ...notification }, ...state.pushNotifications],
      })
  ),
  on(ResourceActions.clearAllFromNotificationStack, (state: GameResource, {}) =>
    Object.assign({}, state, { pushNotifications: [] })
  ),
  on(
    ResourceActions.clearOldestFromNotificationStack,
    (state: GameResource, {}) =>
      Object.assign({}, state, {
        pushNotifications: state.pushNotifications.filter(
          (item, index) => index !== state.pushNotifications.length - 1
        ),
      })
  ),
  on(
    ResourceActions.pushToTransactionStack,
    (state: GameResource, { transaction }) =>
      Object.assign({}, state, {
        transactions: [{ ...transaction }, ...state.transactions],
      })
  ),
  on(ResourceActions.clearAllFromTransactionStack, (state: GameResource, {}) =>
    Object.assign({}, state, {
      transactions: [],
    })
  ),
  on(
    ResourceActions.clearOldestFromTransactionStack,
    (state: GameResource, {}) =>
      Object.assign({}, state, {
        transactions: state.transactions.filter(
          (item, index) => index !== state.transactions.length - 1
        ),
      })
  ),
  on(ResourceActions.addGoal, (state: GameResource, { goal }) => {
    let copyGoals = [...state.goals];
    if (goal.startingGoal) {
      copyGoals.push(goal);
    } else {
      let completedIndex = state.goals.findIndex((x) => x.isComplete === false);
      copyGoals.splice(completedIndex - 1, 0, goal);
    }
    return Object.assign({}, state, { goals: [...copyGoals] });
  }),
  on(ResourceActions.removeGoal, (state: GameResource, { id }) =>
    Object.assign({}, state, {
      goals: [state.goals.filter((x) => x.id !== id)],
    })
  ),
  on(ResourceActions.clearAllGoals, (state: GameResource, {}) =>
    Object.assign({}, state, { goals: [] })
  ),
  on(ResourceActions.updateGoalToComplete, (state: GameResource, { id }) => {
    return {
      ...state,
      goals: [...state.goals].map((goal) => {
        if (goal.id === id) {
          return {
            ...goal,
            isComplete: true,
          };
        } else {
          return goal;
        }
      }),
    };
  })
);
