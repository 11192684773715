<sprk-stack id="content-container">
  <img
    *ngIf="isImage && normalLink"
    sprkStackItem
    src="{{ normalLink }}"
    alt="Card Image"
    [ngClass]="getImageClass()"
    (click)="imageZoom()"
  />
  <iframe
    *ngIf="!isImage"
    sprkStackItem
    [src]="normalLink | safeUrl"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
    class="video-window"
  ></iframe>
  <div id="text-container">
    <ng-container *ngFor="let block of normalText">
      <div [innerHTML]="block | injectvalues" class="text-block"></div>
    </ng-container>
    <div id="explanation-section" *ngIf="explanations?.length > 0">
      <div id="explanation-title">Explanation:</div>
      <div>
        <div *ngFor="let explanation of explanations">
          <div class="explanation" *ngIf="explanation?.isCorrect">
            <span class="explanation-group">Correct - </span>
            {{ explanation?.explanation }}
          </div>
          <div class="explanation" *ngIf="!explanation?.isCorrect">
            <span class="explanation-group">Incorrect - </span>
            {{ explanation?.explanation }}
          </div>
        </div>
      </div>
    </div>
  </div>
</sprk-stack>
