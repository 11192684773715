import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Goal } from 'src/app/model/experiences/goal.model';

export enum AnimationStates {
  None = 'none',
  Entering = 'entering',
  Complete = 'complete',
}

@Component({
  selector: 'app-goal-item',
  templateUrl: './goal-item.component.html',
  styleUrls: ['./goal-item.component.scss'],
  animations: [
    trigger('strikeThrough', [
      transition(
        AnimationStates.None + '=>' + AnimationStates.Complete,
        animate('.5s ease-out')
      ),
      state(
        AnimationStates.None,
        style({
          transform: 'translate(0%)',
          'text-decoration': 'none',
          'background-image': 'linear-gradient(currentColor, currentColor)',
          'background-position': '0% 60%',
          'background-repeat': 'no-repeat',
          'background-size': '0% 2px',
          transition: 'background-size 0.3s',
        })
      ),
      state(
        AnimationStates.Complete,
        style({
          'text-decoration': 'none',
          'background-image': 'linear-gradient(currentColor, currentColor)',
          'background-position': '0% 60%',
          'background-repeat': 'no-repeat',
          'background-size': '100% 2px',
          transition: 'background-size 0.3s',
        })
      ),
    ]),
    trigger('goalItem', [
      transition(
        AnimationStates.Entering + '=>' + AnimationStates.None,
        animate('.7s ease-out')
      ),
      state(AnimationStates.None, style({ transform: 'translate(0%)' })),
      state(AnimationStates.Entering, style({ transform: 'translate(-300%)' })),
    ]),
  ],
})
export class GoalItemComponent implements OnInit, AfterViewInit {
  @Input() goal: Goal;

  goalItemState: AnimationStates = AnimationStates.Entering;
  strikeThroughState: AnimationStates = AnimationStates.None;

  constructor() {}

  ngOnInit(): void {
    if (this.goal.isComplete) {
      this.goalItemState = AnimationStates.None;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.goal.isComplete) {
        this.strikeThroughState = AnimationStates.Complete;
      } else {
        this.goalItemState = AnimationStates.None;
      }
    });
  }
}
