import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as resourceActions from '../../store/actions/game-resource.actions';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  constructor(private readonly _store: Store) {}

  startTimer(): void {
    setInterval(() => {
      this._store.dispatch(resourceActions.setaddTime({ time: 1 }));
    }, 166.67);
  }
}
