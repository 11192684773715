import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { NgForm } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { ConstantsService } from '../../../statics/constants/constants.service';
import { TextsService } from 'src/app/statics/texts/texts.service';
import { ButtonHandlerService } from './../../../services/handler/button-handler/button-handler.service';
import { selectFormInput } from 'src/app/store/selectors/form.selectors';
import * as eventSelectors from '../../../store/selectors/event.selectors';
import * as experienceSelectors from '../../../store/selectors/experience.selectors';
import * as historySelectors from '../../../store/selectors/history.selectors';
import {
  Experience,
  Form,
  Goal,
  RadioOption,
  Transaction,
} from 'src/app/model';
import { History } from '../../../store/reducers';
import { Observable, Subscription } from 'rxjs';
import * as resourceSelector from '../../../store/selectors/game-resource.selectors';

@Component({
  selector: 'app-card-form',
  templateUrl: './card-form.component.html',
  styleUrls: ['./card-form.component.scss'],
})
export class CardFormComponent implements OnInit, OnChanges {
  @Input() cardForm: Form = null;
  @Input() cardTypeClassSelector: string = null;
  @Output() handleAction: EventEmitter<number> = new EventEmitter();

  formValues: Object[] = [];
  defaultValues: Object = {};
  disableButton: boolean = true;
  errorMessage: string = this.TEXT.ERROR_MESSAGE_DEFAULT;
  showModal: boolean = false;
  modalTitle: string = null;
  modalText: string = null;
  modalButton: string = null;
  modalNextAction: number = null;
  radioOptions: RadioOption[] = [];
  selectedCourse: object = {};
  selectedOption1: string;
  selectedOption2: string;
  listenerSubscription: Subscription[] = [];

  cardType: string = null;
  cardType$: Observable<string> = this._store.pipe(
    select(eventSelectors.selectCardType)
  );
  goals: Goal[] = null;
  goals$: Observable<Goal[]> = this._store.pipe(
    select(resourceSelector.selectGoals)
  );
  transaction?: Transaction;
  transactions: Transaction[] = [];
  transactions$: Observable<Transaction[]> = this._store.pipe(
    select(resourceSelector.selectTransactions)
  );
  history: History[] = null;
  history$: Observable<History[]> = this._store.pipe(
    select(historySelectors.selectHistoryStack)
  );
  experience: Experience = null;
  experience$: Observable<Experience> = this._store.pipe(
    select(experienceSelectors.selectExperience)
  );

  constructor(
    readonly CONSTANT: ConstantsService,
    readonly TEXT: TextsService,
    readonly _formHandler: ButtonHandlerService,
    private readonly _store: Store
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    // clear any existing selected option
    this.selectedOption1 = '';
    this.selectedOption2 = '';
    if (
      changes.cardForm.previousValue &&
      changes.cardForm.previousValue.type === this.CONSTANT.RESULT_SUBTYPE
    ) {
      this._formHandler.clearAnswerConditions();
    }
    // Compile list of correct answers for this card.
    if (
      this.cardType === this.CONSTANT.DECISION_CARD_TYPE &&
      this.cardForm.dropdown
    ) {
      this.formValues = [...this.cardForm.dropdown.fields];
      if (Object.keys(this.selectedCourse).length > 0) {
        let correctValues: string[] = [];
        for (let key in this.selectedCourse) {
          if (this.selectedCourse[key]['isCorrect']) {
            correctValues.push(this.selectedCourse[key]['value']);
          }
        }
        this.formValues = this.formValues.map((data: Object) => {
          if (this.selectedCourse[data['field_id']]['isCorrect']) {
            return {
              ...data,
              disabled: true,
              default: this.selectedCourse[data['field_id']]['value'],
            };
          } else {
            let data_array = data['options'].filter((filData: string) => {
              return !correctValues.includes(filData);
            });
            return {
              ...data,
              options: [...data_array],
              default: this.selectedCourse[data['field_id']]['value'],
            };
          }
        });
      }
    }

    // Randomly sort answers to radio questions (unless it's a yes no question).
    if (this.cardForm?.radio) {
      if (this.cardForm?.radio?.options?.find((x) => x.title === 'Yes')) {
        this.radioOptions = [...this.cardForm.radio?.options];
      } else {
        this.radioOptions = [...this.cardForm.radio?.options].sort(
          () => Math.random() - 0.5
        );
      }
    }
  }

  ngOnInit(): void {
    this.listenerSubscription.push(
      this.goals$.subscribe((data) => (this.goals = data))
    );
    this.listenerSubscription.push(
      this.transactions$.subscribe((data) => (this.transactions = data))
    );
    this.listenerSubscription.push(
      this.cardType$.subscribe((data) => (this.cardType = data))
    );
    this.listenerSubscription.push(
      this.history$.subscribe((data) => (this.history = data))
    );
    this.listenerSubscription.push(
      this.experience$.subscribe((data) => (this.experience = data))
    );
    this.listenerSubscription.push(
      this._store
        .pipe(select(selectFormInput))
        .subscribe((data) => (this.selectedCourse = data))
    );
  }

  /**
   * DropDown Handler
   */
  handleFormClick(form: NgForm) {
    this.emitAction(this._formHandler.handleForm(form, this.cardForm));
    this._formHandler.clearFormInputData();
  }

  /**
   * Radio Handler
   */
  handleRadioClick(form: NgForm) {
    this.cardForm?.radio.options.forEach((option) => {
      if (option.on_click === form.value.radio) {
        if (option.modal) {
          this.modalTitle = option.modal.title;
          this.modalText = option.modal.text;
          this.modalButton = option.modal.button_title;
          this.modalNextAction = form.value.radio;
          this.showModal = true;
        } else {
          this.emitAction(form.value.radio);
        }
      }
    });
  }

  /**
   * Modal Click Handler
   */
  handleModalClick() {
    this.showModal = false;
    this.emitAction(this.modalNextAction);
  }

  /**
   * Method to emit @Output() parameter, num represents the id of next action
   */
  emitAction(num: number) {
    this.disableButton = true;
    this.handleAction.emit(num);
  }

  /**
   * Check the user's input for duplicates.
   * @param inputs The answers the user had selected.
   * @returns Whether or not the inputs contain duplicates.
   */
  checkDuplicates(inputs: string[]): boolean {
    return new Set(inputs).size !== inputs.length;
  }

  /**
   * Form handler
   */
  validateFormInputs(event, formSelect: NgForm): void {
    const keysList = ['name', 'viewModel'];
    let formRaw = [...formSelect['_directives']];
    let form = { value: {} };

    if (event?.target.id === 'COURSE_1') {
      this.selectedOption1 = formRaw[0].viewModel;
    } else if (event?.target.id === 'COURSE_2') {
      this.selectedOption2 = formRaw[1].viewModel;
    }

    formRaw.forEach((inputs) => {
      let required_values = keysList.reduce(
        (obj, key) => ({ ...obj, [key]: inputs[key] }),
        {}
      );
      form.value[required_values['name']] = required_values['viewModel'];
    });
    this.defaultValues = form.value;
    if (!Object.values(form.value).includes('dropdownDefault')) {
      if (!this.checkDuplicates(Object.values(this.defaultValues))) {
        this.disableButton = false;
      } else {
        this.disableButton = true;
        this.errorMessage = this.TEXT.ERROR_MESSAGE_DUPLICATE;
      }
    }
  }

  /**
   * Checks if the facts section should be shown.
   * @returns Boolean value representing if the current card contains a fact section.
   */
  getShowFact(): boolean {
    return this.cardForm?.fact ? true : false;
  }

  /**
   * Checks if a radio form should be shown instead of a dropdown.
   * @returns Boolean value representing if a radio form should be shown.
   */
  getShowRadio(): boolean {
    return (
      this.cardForm?.radio && this.cardType !== this.CONSTANT.CORRECT_RESULT
    );
  }

  /**
   * Checks if the continue button should be spawned instead of a form.
   * @returns Boolean value representing if the continue button should be spawned.
   */
  getShowContinue(): boolean {
    if (
      this.cardForm?.radio &&
      this.cardType !== this.CONSTANT.DECISION_CARD_TYPE
    ) {
      return this.cardType === this.CONSTANT.CORRECT_RESULT;
    } else if (
      !this.cardForm?.radio &&
      this.cardType !== this.CONSTANT.DECISION_CARD_TYPE
    ) {
      return true;
    }
  }

  /**
   * Provides the correct action field for the button to use.
   * @returns Action number for the current button.
   */
  getButtonAction(): number {
    switch (this.cardType) {
      case this.CONSTANT.CORRECT_RESULT:
        return this.cardForm.button.on_click_correct;
      case this.CONSTANT.INCORRECT_RESULT:
        return this.cardForm.button.on_click_incorrect;
      case this.CONSTANT.DECISION_CARD_TYPE:
        return this.cardForm.button.on_click;
    }
  }

  /**
   * Checks if the transactions exist and if they should be shown.
   * @returns Boolean value representing if the transactions should be shown.
   */
  shouldShowTransaction(): boolean {
    this.transaction = null;
    if (this.history?.length > 0) {
      const lastHistoryItem = this.history[this.history.length - 1];
      const action = this.experience.actions.find(
        (action) => action.id === lastHistoryItem.actionNo
      );
      if (action.transaction_id >= 0) {
        this.transaction = this.transactions.find(
          (transaction) => transaction.id === action.transaction_id
        );
        return true;
      }
    }
    return false;
  }

  // Workaround styling for Spark Design HTML Stepper
  // https://html.sparkdesignsystem.com/?path=/docs/components-stepper--default-story
  getGoalItemClass(goal: Goal, index: number): string {
    let className = '';
    if (goal.isComplete) {
      className += 'strikethrough ';
    }
    if (index === 0) {
      className += 'sprk-c-Stepper__step--first';
    } else if (index === this.goals.length - 1) {
      className += 'sprk-c-Stepper__step--last';
    }
    return className;
  }

  // Unsubscribing from subscriptions at the end
  ngOnDestroy(): void {
    this.listenerSubscription.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
