import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import * as resourceSelector from '../../../store/selectors/game-resource.selectors';
import { Store, select } from '@ngrx/store';
import { TimerService } from '../../../services/timer/timer.service';
import { Goal } from 'src/app/model/experiences/goal.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  hours: number = 0;
  minutes: number = 0;
  seconds: number = 0;
  listenerSubscription: Subscription[] = [];

  totalSeconds: number = null;
  totalSeconds$: Observable<number> = this._store.pipe(
    select(resourceSelector.selectTime)
  );
  money: number = null;
  money$: Observable<number> = this._store.pipe(
    select(resourceSelector.selectMoney)
  );

  goals: Goal[] = null;
  goals$: Observable<Goal[]> = this._store.pipe(
    select(resourceSelector.selectGoals)
  );
  rotateDegree: number = 0;

  constructor(
    private readonly _store: Store,
    private readonly _timer: TimerService
  ) {}

  ngOnInit(): void {
    this._timer.startTimer();
    this.listenerSubscription.push(
      this.goals$.subscribe((data) => (this.goals = data))
    );
    this.listenerSubscription.push(
      this.money$.subscribe((data) => (this.money = data))
    );
    this.listenerSubscription.push(
      this.totalSeconds$.subscribe((data) => {
        this.totalSeconds = data;
        this.hours = Math.floor(this.totalSeconds / 3600);
        this.totalSeconds %= 3600;
        this.minutes = Math.floor(this.totalSeconds / 60);
        this.seconds = this.totalSeconds % 60;
      })
    );
  }

  /**
   * @returns The angle of rotation for the minute hand.
   */
  getMinuteRotateDegree(): string {
    const value = 360 * (this.minutes / 60) - 180 + 45;
    return value + 'deg';
  }

  /**
   * @returns The angle of rotation for the hour hand.
   */
  getHourRotateDegree(): string {
    const minuteRatio =
      (360 / 12) * ((360 * (this.minutes / 60) - 180 + 55) / 360);

    const value = 360 * (this.hours / 12) - 180 + 55 + minuteRatio;
    return value + 'deg';
  }

  // Unsubscribing from subscriptions at the end
  ngOnDestroy(): void {
    this.listenerSubscription.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
