import { createAction, props } from '@ngrx/store';
import GeoEvent from 'src/app/model/experiences/geoevent.model';

export const setEventId = createAction(
  '[Game Screen] Set Event Id',
  props<{ eventId: number }>()
);

export const setEventCardType = createAction(
  '[Game Screen] Set Event Card Type',
  props<{ cardType: string }>()
);

export const setEventGeoData = createAction(
  '[Game Screen] Setting Event Geo Data',
  props<{ geoEvent: GeoEvent }>()
);
