import { State } from '../reducers';
import { createSelector } from '@ngrx/store';

export const selectResource = (state: State) => state.gameResource;

export const selectMoney = createSelector(
  selectResource,
  (state) => state?.money
);

export const selectTime = createSelector(
  selectResource,
  (state) => state?.time
);

export const selectPushNotifications = createSelector(
  selectResource,
  (state) => state?.pushNotifications
);

export const selectTransactions = createSelector(
  selectResource,
  (state) => state?.transactions
);

export const selectGoals = createSelector(
  selectResource,
  (state) => state?.goals
);
