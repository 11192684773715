import { ExperienceState } from './index';
import { createReducer, on } from '@ngrx/store';
import * as ExperienceActions from '../actions/experience.actions';

export const initialState: ExperienceState = {
  experience: null,
};

export const ExperienceReducer = createReducer(
  initialState,
  on(
    ExperienceActions.setExperience,
    (state: ExperienceState, { experience }) =>
      Object.assign({}, state, { experience: { ...experience } })
  )
);
