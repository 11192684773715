<router-outlet></router-outlet>
<app-footer
  *ngIf="
    this.router.url === '/home' ||
    this.router.url === '/home?inPerson=true' ||
    this.router.url === '/primer' ||
    this.router.url === '/offboarding' ||
    this.screenWidth > CONSTANT.TABLET_PORTRAIT_THRESHOLD
  "
></app-footer>
