import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsService } from 'src/app/statics/constants/constants.service';

enum AnimationStates {
  None = 'none',
  Entering = 'entering',
}
@Component({
  templateUrl: './persona-primer.component.html',
  styleUrls: ['./persona-primer.component.scss'],
  animations: [
    trigger('primerCard', [
      transition('entering => none', animate('700ms 0s ease-in')),
      state('none', style({ transform: 'translateY(0)', opacity: 1 })),
      state('entering', style({ transform: 'translateY(8%)', opacity: 0 })),
    ]),
  ],
})
export class PersonaPrimerComponent implements OnInit {
  animationState: AnimationStates = AnimationStates.Entering;
  isDesktop: boolean;

  constructor(
    private readonly _router: Router,
    private readonly CONSTANT: ConstantsService
  ) {}

  ngOnInit(): void {
    if (window.innerWidth < this.CONSTANT.TABLET_PORTRAIT_THRESHOLD) {
      this.isDesktop = false;
    } else {
      this.isDesktop = true;
    }
    setTimeout(() => {
      this.animationState = AnimationStates.None;
    });
  }

  handleClick() {
    this._router.navigate(['/personas']);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < this.CONSTANT.TABLET_PORTRAIT_THRESHOLD) {
      this.isDesktop = false;
    } else {
      this.isDesktop = true;
    }
  }
}
