import { createAction, props } from '@ngrx/store';
import { Persona } from 'src/app/model/personas/persona.model';

export const setPersonas = createAction(
  '[Persona Select Page] Set Persona',
  props<{ personas: Persona[] }>()
);

export const selectPersona = createAction(
  '[Location Select Page] Select The Persona To Play As',
  props<{ selectedId: number }>()
);
