<ng-template [ngIf]="displayModal">
  <div [ngClass]="'sprk-c-Modal ' + modalContainer" role="dialog" tabindex="-1">
    <div class="sprk-o-Stack sprk-o-Stack--large">
      <header
        [ngClass]="'sprk-o-Stack__item sprk-c-Modal__header ' + modalHeader"
      >
        <img
          src="{{ headerImage }}"
          alt="logo"
          id="header-logo"
          style="height: 13vh"
        />
        <ng-template [ngIf]="showCancelIcon">
          <button
            class="sprk-c-Modal__icon"
            data-sprk-modal-cancel="exampleInfoModal"
            type="button"
            aria-label="Close Modal"
            (click)="hideModal()"
          >
            <svg
              class="sprk-c-Icon"
              viewBox="0 0 64 64"
              aria-hidden="true"
              focusable="false"
            >
              <use xlink:href="#close"></use>
            </svg>
          </button>
        </ng-template>
      </header>
      <div [ngClass]="'sprk-o-Stack__item sprk-c-Modal__body ' + modalDesc">
        <p
          class="sprk-b-TypeBodyTwo modal-text"
          [innerHtml]="text | sanitizehtml"
        ></p>
      </div>
      <ng-template [ngIf]="showButton">
        <footer class="sprk-o-Stack__item modal-footer">
          <button
            [ngClass]="'sprk-c-Button sprk-u-mrm ' + modalButton"
            (click)="continueModal()"
          >
            {{ ButtonText }}
          </button>
        </footer>
      </ng-template>
    </div>
  </div>
  <div
    data-sprk-modal-mask="true"
    class="sprk-c-ModalMask"
    tabindex="-1"
    (click)="hideModal()"
  ></div>
</ng-template>
