import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { History } from '../../../store/reducers';
import * as Actions from '../../../store/actions';
import * as HistorySelectors from '../../../store/selectors/history.selectors';
import { Observable } from 'rxjs';
import { ActionHandlerService } from '../action-handler/action-handler.service';
import { ConstantsService } from 'src/app/statics/constants/constants.service';
import * as historyActions from '../../../store/actions/history.actions';

@Injectable({
  providedIn: 'root',
})
export class StateHandlerService {
  history: History[] = null;
  history$: Observable<History[]> = this._store.pipe(
    select(HistorySelectors.selectHistoryStack)
  );

  constructor(
    private readonly _store: Store,
    private _action: ActionHandlerService,
    private readonly CONSTANT: ConstantsService
  ) {
    this.history$.subscribe((data) => (this.history = data));
  }

  /**
   * Calls store actions to clear all game state data.
   */
  clearGameState() {
    this._store.dispatch(Actions.setTime({ time: null }));
    this._store.dispatch(Actions.setMoney({ money: null }));
    this._store.dispatch(Actions.clearAllGoals());
    this._store.dispatch(Actions.clearAllFromNotificationStack());
    this._store.dispatch(Actions.clearAllFromTransactionStack());
    this._store.dispatch(Actions.setEventId({ eventId: null }));
    this._store.dispatch(Actions.setEventCardType({ cardType: null }));
    this._store.dispatch(Actions.setCard({ history: null }));
    this._store.dispatch(Actions.setPersonas({ personas: null }));
    this._store.dispatch(Actions.selectPersona({ selectedId: null }));
    this._store.dispatch(Actions.setExperience({ experience: null }));
    this._store.dispatch(Actions.updateLocation({ position: null }));
  }

  /**
   * Restores game session from cached history.
   */
  restoreGameState() {
    // Use latest history entry to rehydrate the game state
    let recentHistory: History = this.history[this.history.length - 1];
    this._store.dispatch(historyActions.popHistory());

    // Set game state properties
    this._store.dispatch(
      Actions.setTime({ time: recentHistory.gameResource.time })
    );
    this._store.dispatch(
      Actions.setMoney({
        money: recentHistory.gameResource.money
          ? recentHistory.gameResource.money
          : 0,
      })
    );
    this._store.dispatch(Actions.setEventId({ eventId: recentHistory.cardNo }));
    this._store.dispatch(
      Actions.setEventCardType({ cardType: recentHistory.cardType })
    );

    this._action.actionHandler(
      recentHistory.actionNo,
      recentHistory.cardType === this.CONSTANT.DECISION_CARD_TYPE
        ? recentHistory.cardNo + 1
        : recentHistory.cardNo,
      recentHistory.gameResource.time.toString()
    );
  }
}
