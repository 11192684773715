import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SparkAngularModule } from '@sparkdesignsystem/spark-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';

import { AppComponent } from './app.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { LandingComponent } from './pages/landing/landing.component';
import { PersonaSelectComponent } from './pages/persona-select/persona-select.component';
import { ButtonComponent } from './components/shared/button/button.component';
import { CarouselButtonsComponent } from './components/shared/carousel-buttons/carousel-buttons.component';
import { ProgressBarComponent } from './components/shared/progress-bar/progress-bar.component';
import { GameScreenComponent } from './pages/game-screen/game-screen.component';

import { reducers, metaReducers } from './store/reducers';
import { ReadJSONService } from './services/read-json/read-json.service';
import { ButtonHandlerService } from './services/handler/button-handler/button-handler.service';
import { ActionHandlerService } from './services/handler/action-handler/action-handler.service';
import { TimerService } from './services/timer/timer.service';
import { ResourceHandlerService } from './services/handler/resource-handler/resource-handler.service';
import { ConstantsService } from './statics/constants/constants.service';
import { SanitizeHtmlPipe } from './pipes/html-sanitizer.pipe';
import { RemoveUnderScorePipe } from './pipes/remove-underscore.pipe';
import { DoubleDigitPipe } from './pipes/double-digit.pipe';
import { TextsService } from './statics/texts/texts.service';
import { CardDescriptionComponent } from './components/card-components/card-description/card-description.component';
import { CardFormComponent } from './components/card-components/card-form/card-form.component';
import { CardTableComponent } from './components/card-components/card-table/card-table.component';
import { PhoneComponent } from './components/shared/phone/phone.component';
import { PhoneNotificationComponent } from './components/shared/phone-notification/phone-notification.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { GoalItemComponent } from './components/shared/goal-item/goal-item.component';
import { MobileHeaderComponent } from './components/shared/mobile-header/mobile-header.component';
import { ModalComponent } from './components/shared/modal/modal.component';
import { GameCardComponent } from './components/game-card/game-card.component';
import { SentenceCasePipe } from './pipes/sentence-case.pipe';
import { InjectValuesPipe } from './pipes/inject-values.pipe';
import { CardGeoEventComponent } from './components/card-components/card-geo-event/card-geo-event.component';
import GeolocationManager from './services/geo-event/geo-event.service';
import { MapComponent } from './components/map/map.component';
import { PersonaPrimerComponent } from './pages/persona-primer/persona-primer.component';
import { IconSetComponent } from './components/icon-set/icon-set.component';
import { OffboardingComponent } from './pages/offboarding/offboarding.component';
import { HammerModule } from '@angular/platform-browser';
import 'hammerjs';
import { SafeUrlPipe } from './pipes/safe-url.pipe';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    LandingComponent,
    PersonaSelectComponent,
    GameScreenComponent,
    SanitizeHtmlPipe,
    ButtonComponent,
    RemoveUnderScorePipe,
    SentenceCasePipe,
    CardDescriptionComponent,
    CardFormComponent,
    CardTableComponent,
    DoubleDigitPipe,
    PhoneComponent,
    PhoneNotificationComponent,
    SidebarComponent,
    GoalItemComponent,
    MobileHeaderComponent,
    ModalComponent,
    GameCardComponent,
    ProgressBarComponent,
    InjectValuesPipe,
    CarouselButtonsComponent,
    CardGeoEventComponent,
    MapComponent,
    IconSetComponent,
    PersonaPrimerComponent,
    OffboardingComponent,
    SafeUrlPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SparkAngularModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    HammerModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
  ],
  providers: [
    ReadJSONService,
    ConstantsService,
    TextsService,
    ButtonHandlerService,
    ActionHandlerService,
    ResourceHandlerService,
    TimerService,
    GeolocationManager,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
