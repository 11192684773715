import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConstantsService {
  IN_PERSON_EXP: string = 'IN_PERSON_EXPERIENCE';
  VIRTUAL_EXP: string = 'VIRTUAL_EXPERIENCE';
  PAGE_PRIMARY_SUBTYPE: string = 'PAGE_PRIMARY';
  PAGE_SECONDARY_SUBTYPE: string = 'PAGE_SECONDARY';
  RESULT_SUBTYPE: string = 'RESULT';
  UW_ABOUT_PAGE: string =
    'https://www.weareunited.com/servlet/eAndar.article/106/ABOUT-US';
  RIS_HOME_PAGE: string = 'https://rocketinnovationstudio.ca';
  UW_EMAIL_CONTACT: string = 'info@weareunited.com';
  FORM_TYPE_WITHOUT_MANDATORY: string = 'FORM_WITHOUT_MANDATORY';
  MODAL_PENALTY_TYPE: string = 'PENALTY';
  MODAL_GAMESCREEN_MENU_CHARACTER_INFO: string = 'GAME_MENU_CHARACTER_INFO';
  TABLET_PORTRAIT_THRESHOLD: number = 1079;
  DECISION_CARD_TYPE: string = 'DECISION';
  REFLECTION_CARD_TYPE: string = 'REFLECTION';
  GEOEVENT_CARD_TYPE: string = 'GEOEVENT';
  CORRECT_RESULT: string = 'CORRECT_RESULT';
  INCORRECT_RESULT: string = 'INCORRECT_RESULT';
  constructor() {}
}
