import { createSelector } from '@ngrx/store';
import { State } from '../reducers';

export const selectEventState = (state: State) => state.eventState;

export const selectEventId = createSelector(
  selectEventState,
  (state) => state?.eventId
);

export const selectCardType = createSelector(
  selectEventState,
  (state) => state?.cardType
);

export const selectGeoEventData = createSelector(
  selectEventState,
  (state) => state?.geoEvent
);
