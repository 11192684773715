import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'doubledigit' })
export class DoubleDigitPipe implements PipeTransform {
  constructor() {}

  transform(value: number): string {
    if (value < 10) {
      return '0' + value;
    }
    return value.toString();
  }
}
