import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Form } from 'src/app/model/experiences/form.model';
import { AnswerResponse } from 'src/app/model/experiences/answer-response.model';

import { ConstantsService } from 'src/app/statics/constants/constants.service';
import {
  clearFormInput,
  clearCorrectInput,
  setIncorrectInput,
  setFormInput,
} from 'src/app/store/actions/form.actions';

@Injectable({
  providedIn: 'root',
})
export class ButtonHandlerService {
  answerCheckResponse: AnswerResponse[] = [];
  formData: object = null;

  constructor(
    private readonly _store: Store,
    readonly CONSTANT: ConstantsService
  ) {}

  /**
   * maps 'key:value' pair to 'value:key'
   * @param input any key value pair of string type
   */

  swapKeyValue = (input: object) => {
    let result = {};
    for (let key in input) {
      result[input[key]] = key;
    }
    return result;
  };

  /**
   * takes two arrays as an input, compare the values and return the total number of matches
   * @param array1 First array Input
   * @param array2 Second array Input
   */

  compareArrays = (array1: string[], array2: string[]): number =>
    array1.reduce((accum, current) => accum + +array2.includes(current), 0);

  /**
   * returns total number of matches based on different conditions
   * @param formValues user inputs of form
   * @param condition wrong answer condition
   * @param rawFormData raw user input form data object
   * @param expectedAnswers expected answers for each options
   */

  getTotalMatchesByPosition = (
    formValues: string[],
    condition: object,
    rawFormData: object,
    expectedAnswers: object
  ): number => {
    let valuesToMap = condition['options'];
    if (
      valuesToMap.length > 1 &&
      formValues.join().includes(valuesToMap.join())
    ) {
      return valuesToMap.length;
    } else if (valuesToMap.length === 1) {
      if (
        condition['hasMultipleAnswers'] &&
        formValues.includes(valuesToMap[0])
      ) {
        let formDataSwapped = this.swapKeyValue(rawFormData);
        let input_id = formDataSwapped[valuesToMap[0]];
        let correctAnswer = expectedAnswers[input_id];
        if (
          correctAnswer &&
          correctAnswer.includes(valuesToMap[0]) &&
          condition['isCorrect']
        )
          return 1;
        else if (
          correctAnswer &&
          !correctAnswer.includes(valuesToMap[0]) &&
          !condition['isCorrect']
        )
          return 1;
        else return 0;
      } else if (formValues.includes(valuesToMap[0])) {
        return 1;
      }
    }
    return 0;
  };

  /**
   *
   * @param card the current card data
   * @param form_data the inputs selected by the users
   *
   */

  checkAnswer(cardForm: Form, form_data: Object) {
    this.answerCheckResponse = [];
    let isCorrect: boolean[] = [];

    if (cardForm.dropdown.handle_submit.answer_condition) {
      let formValues = [...new Set(Object.values(form_data))]; //extract the values from the object and create a unique array
      const answerCondition = [
        ...cardForm.dropdown.handle_submit.answer_condition,
      ]; //extract the answer condition
      const expectedAnswers = {
        ...cardForm.dropdown.handle_submit.expected_answers,
      }; //extract the answer condition

      answerCondition.sort((a, b) =>
        a.options.length > b.options.length ? -1 : 1
      ); //sort the answer conditions in descending order of their options count
      answerCondition.forEach((condition) => {
        let arrayCompareRes =
          cardForm.dropdown.handle_submit.type ===
          this.CONSTANT.FORM_TYPE_WITHOUT_MANDATORY
            ? this.getTotalMatchesByPosition(
                formValues,
                condition,
                form_data,
                expectedAnswers
              )
            : this.compareArrays(formValues, condition.options);
        if (
          formValues.length > 0 &&
          arrayCompareRes > 0 &&
          arrayCompareRes === condition.options.length
        ) {
          formValues = formValues.filter(
            (item) => !condition.options.includes(item)
          );
          this.answerCheckResponse.push({
            explanation: condition.explanation,
            isCorrect: condition.is_correct,
          });
          isCorrect.push(condition.is_correct);
        }
      });
      this.answerCheckResponse.sort((a, b) => (a['is_correct'] ? -1 : 1));
      if (formValues.length > 0 && !isCorrect.includes(false)) {
        this.answerCheckResponse.length = 0;
      }
    }
  }

  /**
   * Determines whether the submitted answers are correct.
   * @param formData User inputed form data.
   * @param cardForm Game experience event card form.
   * @returns Action number as defined by experience file data.
   */
  handleForm(formData: NgForm, cardForm: Form): number {
    this.clearAnswerConditions();
    const keysList = ['name', 'viewModel'];
    let formRaw = [...formData['_directives']];

    let form = { value: {} };
    formRaw.forEach((inputs) => {
      let required_values = keysList.reduce(
        (obj, key) => ({ ...obj, [key]: inputs[key] }),
        {}
      );
      form.value[required_values['name']] = required_values['viewModel'];
    });

    let handleSubmit = cardForm.dropdown.handle_submit;
    let hasIncorrect: boolean = false;
    if (handleSubmit.type === this.CONSTANT.FORM_TYPE_WITHOUT_MANDATORY) {
      for (let key in handleSubmit.expected_answers) {
        if (!handleSubmit.expected_answers[key].includes(form.value[key])) {
          hasIncorrect = true;
          this._store.dispatch(
            setFormInput({
              form_input: {
                [key]: { value: form.value[key], isCorrect: false },
              },
            })
          );
        } else {
          this._store.dispatch(
            setFormInput({
              form_input: {
                [key]: { value: form.value[key], isCorrect: true },
              },
            })
          );
        }
      }
      if (hasIncorrect) {
        this.checkAnswer(cardForm, form.value);
        return handleSubmit.else;
      } else {
        return handleSubmit.if_match;
      }
    } else {
      let matchCount: number = 0; //variable to store total count, where mandatory field has value of 2 and optional has value of 1
      let answerMatch: number = 0;
      for (let field in cardForm.dropdown.fields) {
        let key = cardForm.dropdown.fields[field].field_id;
        let value = form.value[key];
        if (handleSubmit.expected_answers['MANDATORY'].includes(value)) {
          this._store.dispatch(
            setFormInput({
              form_input: { [key]: { value: value, isCorrect: true } },
            })
          );
          matchCount += 2;
          answerMatch += 1;
        } else if (handleSubmit.expected_answers['OPTIONAL'].includes(value)) {
          matchCount += 1;
          answerMatch += 1;
          this._store.dispatch(
            setFormInput({
              form_input: { [key]: { value: value, isCorrect: true } },
            })
          );
        } else {
          this._store.dispatch(
            setFormInput({
              form_input: { [key]: { value: value, isCorrect: false } },
            })
          );
        }
      }

      if (
        answerMatch === cardForm.dropdown.fields.length &&
        matchCount <
          cardForm.dropdown.fields.length +
            handleSubmit.expected_answers.MANDATORY.length
      ) {
        this._store.dispatch(setIncorrectInput());
      } else if (matchCount === 0) {
        this._store.dispatch(clearCorrectInput());
      }
      this.formData = form.value;
      //check if the total count is equal to sum of number of dropdowns and no of mandatory fields
      if (
        cardForm.dropdown.fields.length +
          handleSubmit.expected_answers.MANDATORY.length ===
        matchCount
      ) {
        this._store.dispatch(clearFormInput());
        return handleSubmit.if_match;
      } else {
        this.checkAnswer(cardForm, form.value);
        return handleSubmit.else;
      }
    }
  }

  getAnswerConditions(): AnswerResponse[] {
    return [...this.answerCheckResponse];
  }

  getFormInput(): object {
    return this.formData;
  }

  clearFormInputData(): void {
    this.formData = null;
  }

  clearAnswerConditions(): void {
    this.answerCheckResponse.length = 0;
  }
}
