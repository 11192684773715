<div id="content-container">
  <div *ngIf="showMap" class="map-container">
    <app-map [mapProperties]="geoEvent?.map_parameters" [debugMode]="debugMode" [helpMeToggle]="helpMeToggle"></app-map>
  </div>
  <div class="description-container">
    {{ geoEvent?.destination_description }}
  </div>
  <div class="bottom">
    <div class="button-container">
      <app-button
      [buttonText]="'Help Me'"
      [buttonType]="'button-primary'"
      (click)="helpMeClick()"
    >
  </app-button>
      <app-button
        [buttonText]="'I Am Here'"
        [buttonType]="'button-primary'"
        (click)="handleClickEvent()"
      >
      </app-button>
    </div>
  </div>
</div>


<sprk-modal
[(isVisible)]="showModal"
title="Are you sure?"
modalType="info"
(hide)="cancelModal($event)"
>
Only continue if you are at the required location.
<div id="modal-buttons">
  <div id="confirm-button">
    <app-button
      [buttonText]="'Yes'"
      [buttonType]="'button-primary'"
      class="card-button"
      (click)="confirmModal($event)"
    >
    </app-button>
  </div>
  <div id="cancel-button">
    <app-button
      [buttonText]="'No'"
      [buttonType]="'button-transparent'"
      class="card-button"
      (click)="cancelModal($event)"
    >
    </app-button>
  </div>
</div>
</sprk-modal>