import { PersonaState } from './index';
import { createReducer, on } from '@ngrx/store';
import * as PersonaActions from '../actions/persona.actions';
import { Persona } from 'src/app/model';

export const initialState: PersonaState = {
  personas: new Array<Persona>(),
  selectedPersonaId: null,
};

export const PersonaReducer = createReducer(
  initialState,
  on(PersonaActions.setPersonas, (state: PersonaState, { personas }) =>
    Object.assign({}, state, { personas: personas })
  ),
  on(PersonaActions.selectPersona, (state: PersonaState, { selectedId }) =>
    Object.assign({}, state, { selectedPersonaId: selectedId })
  )
);
