import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import DOMPurify from 'dompurify';

@Pipe({ name: 'sanitizehtml' })
export class SanitizeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}

  /**
   * Explanation: to display dynamic inline styles, html strings need to be
   * passed into components using [innerHtml]. However, this only works if
   * that content is passed through DomSanitizer. DomSanitizer is too strict
   * in terms of what it strips out of an html string, so we need to bypass
   * DomSanitizer using byPassSecurityTrustHtml. Instead, we use the DOMPurify
   * package to sanitize our input.
   **/
  transform(value): SafeHtml {
    const sanitizedContent = DOMPurify.sanitize(value);
    return this.sanitized.bypassSecurityTrustHtml(sanitizedContent);
  }
}
