import { State } from '../reducers';
import { createSelector } from '@ngrx/store';

export const selectPersonaState = (state: State) => state.personaState;

export const selectSelectedId = createSelector(
  selectPersonaState,
  (state) => state?.selectedPersonaId
);

export const selectPersonas = createSelector(
  selectPersonaState,
  (state) => state?.personas
);
