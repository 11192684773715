<div class="offboarding-container">
  <h1 class="offboarding-title">{{ TEXT.OFF_BOARDING_TITLE }}</h1>
  <div class="offboarding-tabs">
    <div
      *ngFor="let content of offboardingContents; index as i"
      [ngClass]="currentCard === i ? 'current-tab' : ''"
      class="offboarding-tabs-item"
      (click)="onTabChange(i)"
    >
      {{ content["tabTitle"] }}
    </div>
  </div>
  <div class="offboarding-card-container" #offboardingCard>
    <div
      *ngFor="let content of offboardingContents; index as i"
      id="card-{{ i }}"
      class="offboarding-card"
      (swipeleft)="isMobile && onLeftSwipe()"
      (swiperight)="isMobile && onRightSwipe()"
      [@cardSwipe]="animationState"
      (click)="handleCardClick(i)"
    >
      <img
        class="offboarding-image"
        [src]="offboardingContents[i]['image']"
        alt="offboarding image"
      />
      <div class="card-title">{{ offboardingContents[i]["title"] }}</div>
      <div class="offboarding-description">
        {{ offboardingContents[i]["description"] }}
      </div>
      <div class="button-container">
        <app-button
          [buttonText]="offboardingContents[i]['title']"
          [buttonType]="'button-primary'"
          class="card-button"
        ></app-button>
      </div>
    </div>
  </div>
</div>
