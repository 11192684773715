import { createAction, props } from '@ngrx/store';

export const setFormInput = createAction(
  '[Game Screen] Set Form Input',
  props<{ form_input: object }>()
);

export const setIncorrectInput = createAction(
  '[Game Screen] Set InCorrect Input'
);

export const clearCorrectInput = createAction(
  '[Game Screen] Clear Correct Input'
);

export const clearFormInput = createAction('[Game Screen] Clear Form Input');
