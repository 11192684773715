<div id="table-container">
  <sprk-table id="table">
    <thead sprkTableHead>
      <tr>
        <th *ngFor="let thead of tableData?.table_header">
          {{ thead }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let tdata of tableData?.table_data">
        <td *ngFor="let item of tdata">{{ item }}</td>
      </tr>
    </tbody>
  </sprk-table>
</div>
