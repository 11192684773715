import { createReducer, on } from '@ngrx/store';
import { Location } from 'src/app/model/location/location.model';
import * as LocationActions from '../actions/location.actions';

export const initialState: Location = null;

export const LocationReducer = createReducer(
  initialState,
  on(LocationActions.setLocation, (state, { location }) => location)
);
