import { Component, OnInit } from '@angular/core';
import { ConstantsService } from '../../../statics/constants/constants.service';
import { Router } from '@angular/router';
import { TextsService } from 'src/app/statics/texts/texts.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  UW_ABOUT_US: string = this.CONSTANT.UW_ABOUT_PAGE;
  RIS_HOME: string = this.CONSTANT.RIS_HOME_PAGE;
  UW_EMAIL_CONTACT: string = this.CONSTANT.UW_EMAIL_CONTACT;

  constructor(
    readonly CONSTANT: ConstantsService,
    readonly TEXT: TextsService,
    public router: Router
  ) {}

  ngOnInit(): void {}
}
