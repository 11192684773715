<div id="card-container" [@loadCard]="animationState">
  <sprk-card cardType="base" idString="card-default">
    <div
      id="card-content"
      class="
        sprk-o-Stack__item
        sprk-c-Card__content
        sprk-o-Stack sprk-o-Stack--medium
      "
    >
      <div id="icon-container">
        <sprk-icon
          id="notification-icon"
          iconType="{{ notification?.icon }}"
          additionalClasses="sprk-c-Icon--l sprk-c-Icon--filled-current-color"
        >
        </sprk-icon>
      </div>
      <div id="text-container">
        <ng-template ngIf="{{ notification?.title }}">
          <div id="title-div">
            <span id="title">{{ notification?.title }}</span>
            <span id="time">{{ notification?.time }}</span>
          </div>
        </ng-template>
        <div>
          <span id="description">{{ notification?.description }}</span>
        </div>
      </div>
    </div>
  </sprk-card>
</div>
